* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #44577a !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}



/* a{
    color:#44577a!important;
    text-decoration: underline!important;
} */
a:hover,
a:focus {
  text-decoration: none !important;
}

.logoLogin {
  margin-bottom: 30px;
}

.css-v9efdy {
  margin: 32px;
  width: unset !important;
}

.css-gecs57 {
  overflow: unset !important;
}

.placeholder:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.centeredText {
  text-align: center;
  top: 60%;
  position: absolute;
  left: 8%;
}

.galleryClass div {
  margin-top: unset !important;
}

.gallerySecondClass {
  padding-top: unset !important;
}

.gallerySecondClass {
  margin-top: 70px !important;
}

.row-icon {
  text-align: left !important;
}

.ginormous img {
  width: 160px !important;
}

.albumTopCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 99;
  margin-top: 0 !important;
}

.albumCoverImg {
  margin-top: 0 !important;
}

.leftColLink {
  float: left;
  width: 20%;
  /* margin-left: 1px;   */
}

.leftColLink1 {
  float: left;
  width: 40%;
  padding-left: 20px;
}

/* .leftResetColLink {
  float: left;
  width: 30%;

} */

/* .tk1{
    position: relative;
} */

/* .css-1sv4srx-MuiButtonBase-root-MuiListItemButton-root{
    color: #ffffff !important;
}
.mainTitle .MuiListItemText-root {
    color: #849f93 !important;
} */
.loginPanel label {
  top: -1px;
}

.talentChild {
  box-shadow: none !important;
  margin: 0 0 2px 17px !important;
  border-radius: 9px !important;
  width: 170px;
  cursor: pointer;
}

.talentChild .talentChildCaption {
  margin: 6px 10px;
}

.talentTreeBox {
  padding: 15px 0 !important;
}

.album-contain {
  width: fit-content;
  cursor: pointer;
}

.album {
  position: relative;
  border: 1px solid transparent;
  height: 136px;
  overflow: hidden;
  margin-top: -12px;
  border-radius: 7px;
  text-overflow: ellipsis;
  background: rgb(207 208 247 / 50%);
  display: flex;
}

.albummobile img {
  width: 300px !important;
  margin: auto;
  min-height: 9.3rem;
}

.album.ginormous img {
  width: 170px !important;
  /* margin-top: -16px; */
  /* min-height: 9.3rem; */
  margin: auto;
}

.album .centeredText {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 1;
}

.Grid {
  width: 75%;
}

.Grid .albName {
  display: none;
}

.Grid img {
  max-height: 150px;
  max-width: 100%;
}

.Box img {
  max-height: 150px;
  max-width: 100%;
}

.Box {
  flex-wrap: nowrap;
  display: block;
  width: 25%;
}

.Box .albName {
  width: 94%;
  margin: 10px 3% 0;
  text-align: left;
}

.album .albumName {
  position: absolute;
  left: 34px;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 0;
  text-align: left;
  color: #fff;
  font-weight: 500;
  text-shadow: 1px 1px 2px #000;
  line-height: 1.3;
}

.icon-award {
  position: absolute;
  top: 32px;
  right: 28px;
}

.HowerButton:hover {
  text-decoration: none;
  background-color: none !important;
  border: 1px solid #0b4029;
}

.css-1v2nedh-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #0b4029 !important;
}

.css-iqpijo-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #0b4029 !important;
}

/* InBox Css Start  18-04-2022*/
.inboxTab {
  background: #0b4029;
  border: none;
}

.inboxTab h6 {
  color: #fff;
}

.inboxTab .activetab {
  background: #1baa5a;
  border: 2px solid #0b4029;
}

.rightpannel {
  padding: 20px;
}

.rightpannelcard {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.rightpannelcardOne {
  position: relative;
  border: 1px solid #cce0ff;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.rightpannelcard-changes {
  padding: 0;
}

.LeftSideTable tr td {
  border: none;
}

.rightpannelcard h3,
.rightpannelcard p {
  color: #44577a;
}

.rightpannelcard h3 {
  font-size: 17px;
  margin-bottom: 5px;
  /* margin-bottom: 10px; */
}

.rightpannelcard h5 {
  font-size: 12px;
  margin-bottom: 5px;
}

.rightpannelcard p {
  font-size: 15px;
}

.CardLeftSide {
  position: absolute;
  width: 80px;
  left: 10px;
  top: 15px;
  text-align: left;
}

.LeftSideIcon {
  margin: 4px;
}

.IconBox {
  position: absolute;
  cursor: pointer;
  width: 70px;
  right: 0;
  top: 15px;
  text-align: left;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.IconBox .forIcon {
  font-size: 18px;
  text-align: right;
  margin-left: 10px;
}

.CardLeftSide img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.CardLeftSide span {
  display: inline-block;
}

.CardLeftSide p {
  margin-top: 5px;
}

.Fcontainer {
  max-width: 1200px;
  border: 0.5px solid #d3e1ea;
  border-radius: 16px;
}

.LeftSideBox {
  padding: 0px;
  border: 0.1px solid #d3e1ea;
  border-left: none;
  border-right: none;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.LeftSideBox.active {
  background-color: #00ab55;
}

.LeftSideBox tr td {
  padding: 5px;
}

.LeftSideBox tr td.MsgcountBox {
  text-align: right;
  padding-right: 20px;
}

.Leftimg {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
}

.LeftSideCell {
  margin: 0px;
  padding: 0px;
}

.contentBox h5 {
  font-size: 16px;
  color: #44577a;
  font-weight: 500 !important;
}

.contentBox p {
  font-size: 16px;
  color: #8693aa;
}

.LeftSideCell img {
  width: 42px;
  height: 42px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 15px;
}

.msgcount {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  text-align: center;
  color: white;
  margin-left: 0;
}

#RightCard {
  padding: 0px;
  color: #fff;
  box-shadow: none !important;
  background: #16b358;
  width: 350px;
  height: 550px;
}

.NotificationRightCard {
  margin-bottom: 1em;
  border: 0.1px solid #d3e1ea;
}

.NotificationRightCard tr td {
  padding: 5px;
}

.NotificationRightTRow {
  margin: 0em;
  font-size: 10px;
}

.NotificationRightTRow img {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  padding: 5px;
}

.notificationcontainer {
  border-left: 0.5px solid #d3e1ea;
  margin: 0px;
  padding: 0px;
  border-color: 0.5px solid #d3e1ea;
}

/* InBox Css End  18-04-2022*/
@media (min-width: 1200px) {
  .AudioWidth {
    max-width: unset !important;
  }
}

/* .css-1f2yong-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root{
    background-color: #0B4029 !important;
} */
/* .css-fqqnlk-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover{
    background-color: #0B4029 !important;
}
.css-144uuw7-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #0B4029 !important;
}
.css-ixyuf3-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root{
    background-color: #0B4029 !important;
} */
.palleteBox .MuiTreeItem-content {
  background: none !important;
}

.loginImage {
  width: 300px !important;
  position: relative !important;
  background-color: #000;
  background-size: contain;
  object-fit: inherit;
  margin: 0 auto;
  top: 50px;
}

.loginBlock {
  width: 100%;
  position: relative;
  z-index: 99;
}

.loginBlock .MuiOutlinedInput-input {
  height: 30px;
  padding: 8px 15px;
}

.btnCustom {
  background: #0b4029 !important;
  box-shadow: none !important;
  min-width: 150px;
}

.btnCustom:hover,
.btnCustom:focus {
  background: #16b358 !important;
  color: #fff !important;
}

.css-ct8fc-MuiTreeItem-content.Mui-selected {
  background: none !important;
}

.audioEdit {
  position: absolute;
  top: 15px;
  right: 15px;
}

.videoList {
  /* width: 23%; */
  /* width: 290px; */
  position: relative;
  margin-right: 1%;
  float: left;
  margin-bottom: 15px;
}

.videoHolder {
  width: 100%;
  height: 335px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.videoHolder video {
  height: 335px;
  width: auto;
}

.videoWrapper .Grid .videoBox {
  width: 33% !important;
  padding: 10px;
  position: relative;
  height: 100%;
  z-index: 99;
  float: left;
  object-fit: fill;
  max-height: 170px;
  overflow: hidden;
}

/* .videoWrapper .Grid {
  display : unset;
} */
.videoWrapper .Grid .videoBox::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.01);
}

.videoWrapper .Grid .videoBox video {
  position: relative;
  z-index: -1;
  height: 200px;
  margin: 0 auto;
  object-position: top;
  object-fit: cover;
  max-height: 230px;
  width: 230px;
}

.videoWrapper .Box .videoBox {
  position: relative;
  z-index: 99;
  object-fit: fill;
  max-height: 120px;
  overflow: hidden;
}

.videoWrapper .Box .videoBox::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.01);
}

.palleteBoxCaption {
  border-radius: 10px !important;
  margin: 3px !important;
}

/* .css-gjzmv4-MuiPaper-root-MuiCard-root{
    border-radius: 10px !important;
    margin: 3px !important;
} */
.css-o4b71y-MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.css-90h10p-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background-color: #ffffff !important;
}

.or {
  background: #0b4029;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  padding: 8px;
  text-align: center;
  margin: 0 auto;
}

.socialMedia {
  margin: 15px -25px;
  text-align: center;
}

.socialMedia a {
  width: 28%;
  display: inline-block;
  margin: 6px 2%;
  box-shadow: none;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 16px;
  padding: 10px;
  font-weight: 500;
}

.socialMedia svg {
  vertical-align: middle;
}

.socialMedia a:hover {
  opacity: 0.9;
}

.socialMedia a.google {
  background: #dd4b39;
}

.socialMedia a.facebook {
  background: #3b5999;
}

.socialMedia a.instagram {
  background: rgb(217, 158, 77);
  background: -moz-linear-gradient(90deg,
      rgba(217, 158, 77, 1) 0%,
      rgba(128, 40, 222, 1) 100%);
  background: -webkit-linear-gradient(90deg,
      rgba(217, 158, 77, 1) 0%,
      rgba(128, 40, 222, 1) 100%);
  background: linear-gradient(90deg,
      rgba(217, 158, 77, 1) 0%,
      rgba(128, 40, 222, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d99e4d", endColorstr="#8028de", GradientType=1);
}

.socialMedia a.linkedin {
  background: #0077b5;
}

.socialMedia a.behance {
  background: #0057ff;
}

.socialMedia a.twitter {
  background: #1dabdd;
}

.loginLeftPanel {
  box-shadow: none !important;
}

.dialogOTP {
  padding: 20px 20px;
  text-align: center;
}

.dialogOTP .OTPBox {
  padding: 30px;
}

.dialogOTP .btnCustom {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  clear: both;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.thankYouText {
  font-size: 1.1em !important;
}

.linkRow {
  width: 100%;
  float: left;
  clear: both;
}

.leftColLink {
  float: left;
  width: 60%;
  margin-right: 2%;
}

.rightColLink {
  float: left;
  width: 43%;
  text-align: right;
}

.mainTitle .MuiListItemButton-root {
  background: none;
  /* color: rgb(217 228 223) !important; */
}

.mainTitle .css-claira {
  width: 30px;
  height: 45px;
  color: #fff;
}

.logoHeader {
  background: #f8f8f8;
  height: 80px;
  padding-top: 5px !important;
}

.headerWrapper {
  height: 80px !important;
  width: 100% !important;

  background: #f8f8f8;
}

.headerInner {
  background: #f8f8f8;
  height: 80px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sideBar {
  background: linear-gradient(#006743, #001c10);
  border-radius: 10px !important;
}

.sideBar .MuiCollapse-wrapper {
  padding-left: 10px;
}

.sideBar a {
  color: #d7e0dc !important;
  text-decoration: none !important;
  height: 30px;
}

.sideBar a.active {
  color: #ffc300 !important;
  background: none;
}

.RootChild .RootChild1 {
  color: #d7e0dc;
}

.sideBar .dropdownList {
  margin-bottom: 20px;
}

.sideBar .css-nodkat {
  width: 8px;
  height: 8px;
  border-radius: 0;
  background-color: #919eab;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: 5px;
}

.sideBar a.active div span {
  width: 8px;
  height: 8px;
  border-radius: 0;
  background-color: #fff;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: scale(1);
}

/* .css-men9i-MuiPaper-root-MuiDrawer-paper{
    border-right:none!important;
    width:281px!important;
    top:80px!important;
} */
.avtar .MuiAvatar-root {
  background: #0b4029;
  color: #fff;
}

.profileBtn .MuiBadge-root {
  color: #0b4029;
  margin-right: 20px;
}

.profileBtn .MuiBadge-root svg {
  width: 30px;
  height: 30px;
}

.profileBtn .MuiBadge-badge {
  background-color: #0b4029;
  color: #fff;
  top: 22px;
  right: 0;
}

.tableCustom {
  text-align: left;
}

.tableCustom th {
  font-weight: bold;
  width: 30%;
}

.indicator .MuiTabs-indicator {
  background-color: #16a851 !important;
}

.indicatorTheme .MuiTabs-indicatorTheme {
  background-color: #16a851 !important;
}

.TableCustomm {
  border: 1px solid #d3e1ea;
}

.taableRow {
  border-bottom: 1px solid #d3e1ea;
}

.tableCustom1 {
  border: 1px solid #d3e1ea;
  text-align: left;
}

.tableCustom1 th {
  font-weight: bold;
  color: #44577a;
  /* display: flex;
    justify-content: flex-start; */
  padding-left: 15px !important;
}

.tableCustom1 td {
  color: #44577a;
}

.BrowseImage span {
  background: #fff;
}

.BrowseImage {
  /* object-fit: contain !important; */
  background: #fff;
}

.leftlinkRow {
  float: left;
  width: 36%;
  padding-left: 20px;
}

.leftEmailColLink {
  float: left;
  width: 50%;
  padding-left: 65px;
}

.calenderDays {
  width: 75px !important;
  border: 1px solid #d5d5d5 !important;
  display: inline-block;
  margin-right: -5px !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}

.img-award {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.calenderDays:first-child {
  border-radius: 5px 0 0 5px !important;
}

.calenderDays:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.todayBtn {
  border: 1px solid #d5d5d5 !important;
  border-radius: 5px !important;
}

.dateArrowHolder button {
  border: 1px solid #d5d5d5 !important;
  border-radius: 0;
}

.dateArrowHolder .forwardArrow {
  margin-left: -1px !important;
  border-radius: 0 5px 5px 0 !important;
}

.dateArrowHolder button:first-child {
  border-radius: 5px 0 0 5px !important;
}

.fc .fc-col-header th {
  border-color: #f5f5f5 !important;
  background: #d3e1ea;
}

.tableCustom th,
.tableCustom td {
  padding: 8px 5px;
  /* border-bottom:1px solid #999; */
}

.tableUserDone th,
.tableUserDone td {
  padding-left: 0px !important;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 12px !important;
  color: #44577a !important;
  /* border-bottom:1px solid #999; */
}

.tableUserDone tr:last-child th,
.tableUserDone tr:last-child td {
  padding: 5px 0px !important;
  border-bottom: none !important;
}

/* .tableUserDone td {
  padding-left: 10px !important;
} */

.tableCustom1 th,
.tableCustom1 td {
  padding: 15px 5px;
  border-bottom: 1px solid #d3e1ea;
}

.tableCustom tr:last-child th,
.tableCustom tr:last-child td {
  padding: 15px 5px;
  border-bottom: none;
}

.pl-0 {
  padding-left: 0 !important;
}

.tableCustom .websiteName {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.errorMessage {
  position: absolute;
  left: 0px;
  width: 130px;
  top: 40px;
  font-size: 11px;
  color: #ffa707;
}

.urlInput {
  margin-right: 10px !important;
  position: relative;
  display: inline-flex;
  /* width: 160px; */
}

.urlInput input {
  float: left;
  padding: 6px 10px;
  border-radius: 4px !important;
  width: 100%;
}

.doneBlock {
  margin: 20% 0 0 0;
  box-shadow: 0 0 15px #d5d5d5 !important;
  margin-left: -100px;
  margin-right: 100px;
}

.leftDoneBlock {
  height: 100vh;
}

.tableResponsive {
  overflow: auto;
  max-width: 100%;
}

.graph {
  max-width: 500px;
  margin: 0 auto;
}

.progressWrapper {
  position: relative;
}

.progressText {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 2px;
  color: #fff;
}

.progressTextBar {
  position: relative !important;
  z-index: 0 !important;
}



.css-1f2yong-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  box-shadow: none !important;
}

@media (max-height: 750px) {
  .loginBlock .loginPanel {
    height: auto;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.leftDoneBlock {
  height: 100vh;
}

.tableResponsive {
  overflow: auto;
  max-width: 100%;
}

.graph {
  max-width: 500px;
  margin: 0 auto;
}

.talentTree li {
  position: relative;
}

.iconCustom {
  width: auto;
  position: absolute;
  z-index: 9;
  right: 15px;
  top: 8px;
}

.titlesSetting {
  position: absolute;
  background: #fff;
  top: -11px;
  z-index: 99;
  left: 16px;
  padding: 0 15px;
}

.boxSetting {
  overflow: unset !important;
}

.settingNav {
  border: none;
  width: 100px;
  margin: 10px 0 20px 0;
}

.settingNav a {
  float: left;
}

.settingNav .activetab {
  background: none;
  color: #0b4029 !important;
  border-bottom: 2px solid #0b4029;
  border-radius: 0 !important;
}

.fullWidth {
  width: 100%;
}

/* InBox Css Start  18-04-2022*/
.inboxTab {
  background: #0b4029;
  border: none;
}

.inboxTab h6 {
  color: #fff;
}

.inboxTab .activetab {
  background: #1baa5a;
  border: 2px solid #0b4029;
}

.inboxWrapper {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.inboxWrapper:hover,
.inboxSideTable:hover {
  background: #d3e1ea;
}

.inboxWrapper h3,
.inboxWrapper p {
  color: #44577a;
}

.inboxWrapper h3 {
  font-size: 17px;
  margin-bottom: 10px;
}

.inboxWrapper p {
  font-size: 15px;
}

.LeftSideHolder {
  position: absolute;
  width: 80px;
  left: 10px;
  top: 15px;
  text-align: left;
}

.iconBlock {
  position: absolute;
  width: 80px;
  right: 0;
  top: 15px;
  text-align: left;
}

.iconBlock .iconRCol {
  font-size: 18px;
  text-align: right;
  margin-left: 10px;
}

.LeftSideHolder img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.LeftSideHolder span {
  display: inline-block;
}

.LeftSideHolder p {
  margin-top: 5px;
}

.Fcontainer {
  max-width: 1200px;
  border: 0.5px solid #d3e1ea;
  border-radius: 16px;
}

.LeftBox {
  padding: 0px;
  border: 0.1px solid #d3e1ea;
  border-left: none;
  border-right: none;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.LeftBox tr td {
  padding: 5px;
}

.LeftBox tr td.rightTD {
  text-align: left;
  padding-right: 20px;
}

.Leftimg {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
}

.LeftTCell {
  margin: 0px;
  padding: 0px;
}

.centerCell h5 {
  font-size: 16px;
  color: #44577a;
  font-weight: 500 !important;
}

.centerCell p {
  font-size: 16px;
  color: #8693aa;
}

.LeftTCell img {
  width: 42px;
  height: 42px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 15px;
}

.msgcount {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  text-align: center;
  color: white;
  margin-left: 0;
}

#RightCard {
  padding: 0px;

  color: #fff;
  box-shadow: none !important;
  background: #16b358;
  width: 350px;
  height: 550px;
}

.NotificationRightCard {
  margin-bottom: 1em;
  border: 0.1px solid #d3e1ea;
}

.NotificationRightCard tr td {
  padding: 5px;
}

.NotificationRightTRow {
  margin: 0em;
  font-size: 10px;
}

.NotificationRightTRow img {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  padding: 5px;
}

.notificationcontainer {
  border-left: 0.5px solid #d3e1ea;
  margin: 0px;
  padding: 0px;
  border-color: 0.5px solid #d3e1ea;
}

/* InBox Css End  18-04-2022*/

/*  Subscription Css Start 18-04-2022 */
.FirstContainer {
  text-align: center;
  margin: 2em;
}

.SecondContainer {
  text-align: center;
  display: block !important;
}

.ContentSpan {
  padding: 20px 20px 0;
  float: left;
  text-align: center;
  width: 100%;
}

.subPlans #leftCard {
  max-width: 350px;
  height: 550px;
  background-color: #d3e1ea;
  text-align: center;
}

.leftsideBox {
  margin: 2em;
  padding: 1em;
  text-align: center;
}

.leftcardgrid {
  margin: 2em;
  padding: 1em;
  height: 300px;
}

#listUl {
  text-align: left;
}

#listUl li {
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
}

#rightlistUl {
  text-align: left;
}

#rightlistUl li {
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
}

.subPlans .leftButton {
  width: 170px;
  margin: 20px auto 0;
  text-align: center;
  background: #44577a !important;
  border-radius: 5px !important;
}

.subPlans .leftButton button {
  color: #fff !important;
}

.subPlans {
  text-align: center;
  width: 100%;
}

.subPlans .leftGrid {
  text-align: center;
  display: inline-block;
  margin: 30px 20px;
}

/* .leftGrid{
    width:100%;
} */
.subPlans .RightGrid {
  max-width: 350px;
  text-align: center;
  display: inline-block;
  margin: 30px 20px;
}

.rightBlockInbox {
  padding: 20px;
}

#RightCard button {
  color: #212b36;
}

.rightsideBox {
  margin: 2em;
  padding: 1em;
  text-align: center;
}

.rightsideBoxGrid {
  margin: 2em;
  padding: 1em;
  height: 300px;
}

.rightList {
  font-size: 10px;
  margin-right: 10px;
}

.rightButton {
  width: 170px;
  margin: 20px auto 0;
  text-align: center;
  color: #212b36;
  border-radius: 5px !important;
}

.subsamount {
  font-size: 16px;
  font-weight: bold;
}

.rightGridInbox {
  border-left: 1px solid #d3e1ea;
}

/* User Recommendations start */
.margin4 {
  margin: 16px;
}

.margin2 {
  margin: 8px;
}

.card-class {
  height: 79px !important;
  cursor: pointer;
  /* background-color:#BDB4FC !important; */
}

.buttonset {
  display: flex;
  gap: 16px;
}

.tableCustom1 {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
}

.tableCustom1 th {
  font-weight: bold;
  /* color: #44577A; */
  padding-left: 15px !important;
}

.tableCustom1 th,
.tableCustom1 td {
  padding: 15px 5px;
  border-bottom: 1px solid #d3e1ea;
  border-left: 1px solid #d3e1ea;
}

.tableCustom1 .table-main-title {
  text-align: initial !important;
}

.jobModelButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.dateBetweenTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* user Recommendations End */

/* Location Details CSS Start */
.locaPersonalTop {
  border: 1px solid #d3e1ea;
}

.profileBtnLocation {
  margin: 0 !important;
  width: 200px;
  background-color: #0f9143 !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  padding: 10px;
}

.trendingBtnLocation {
  margin: 0 !important;
  width: 200px;
  background-color: #ff754a !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  padding: 10px;
}

.locationBlueBtn {
  margin: 0 0 10px;
}

.locationBlueBtn button {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
}

.qrCodeLocation {
  height: 120px;
  width: 120px;
  /* margin: 15px 15px 0px 50px; */
}

.accordianLocation {
  border: 1px solid #d3e1ea !important;
  border-radius: 0 !important;
  width: calc(100% - 65px);
  margin-left: 65px !important;
}

.accordianLocation[area-expandable="true"] {
  background: red;
}

.locationAccordianTitleHolder {
  align-items: flex-start !important;
}

.locationAccordianTitleHolder .MuiAccordionSummary-content {
  margin: 0 !important;
}

.locationAccordianTitle {
  position: relative;
  padding: 10px 30px;
  color: #fff;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 0 -16px !important;
}

.titleIcon {
  position: absolute;
  left: -65px;
  width: 65px;
  height: 50px;
  top: 0;
}

.accordianBlueTitle {
  background: #3a66e6;
}

.accordianBlueTitle .titleIcon {
  background: url("../src/images/personal-icon.png") no-repeat center center #1246df;
}

.accordianCeruleanTitle {
  background: #1bc9e4;
  width: 100%;
}

.accordianCeruleanTitle .titleIcon {
  background: url("../src/images/education-icon.png") no-repeat center center #07a5ce;
}

.accordianLanguageTitle {
  background: #3A66E6;
  width: 100%;
}

.accordianLanguageTitle .titleIcon {
  background: url("../src/images/Language_icon.png") no-repeat center center #07A5CE;
  background-size: 25px;
}

.accordianYellowTitle {
  background: #ffc455;
  width: 100%;
}

.accordianYellowTitle .titleIconInner {
  background: url("../src/images/appearance-icon.png") no-repeat center center #eba31b;
}

.accordianRedTitle {
  background: #ff754a;
  width: 100%;
}

.accordianRedTitle .titleIcon {
  background: url("../src/images/profession-skillset.jpg") no-repeat center center #ef4713;
}

.accordianBlueTitle {
  background: #6b96e6;
  width: 100%;
}

.accordianBlueTitle .titleIconInner {
  background: url("../src/images/work-experience.jpg") no-repeat center center #0f347a;
}

.accordianOranceTitle {
  background: #ffc455;
  width: 100%;
}

.accordianOranceTitle .titleIcon {
  background: url("../src/images/social-icon.jpg") no-repeat center center #eba31b;
}

.accordianGreenTitle {
  background: #16a851;
  width: 100%;
}

.accordianGreenTitle .titleIcon {
  background: url("../src/images/work-exp-icon.png") no-repeat center center #0f9143;
}

.accordianVioletTitle {
  background: #a62bec;
  width: 100%;
}

.accordianEnquiryTitle {
  background: #db57a6;
}

.accordianEnquiryTitle .titleIcon {
  background: url("../src/images/work-exp-icon.png") no-repeat center center #0f9143;
}

.accordianVioletTitle .titleIcon {
  background: url("../src/images/portfolio-icon.png") no-repeat center center #8317c1;
}

.profileImgLocation {
  /* max-height: 500px; */
  max-width: 100%;
  height: auto;
  width: 100%;
  /* margin: 0 auto; */
}

.locationTable table {
  border: 1px solid #d3e1ea;
  border-radius: 15px;
  /* margin-bottom: 20px; */
}

.locationTable table tr th {
  box-shadow: none !important;
  background: none !important;
  color: #44577a;
  /* border-radius: 10px !important; */
}

/* .locationTable table tr {
  border-bottom: 1px solid #d3e1ea;
} */

.locationTableTheme table {
  border: 1px solid #d3e1ea;
  border-radius: 15px;
  margin-bottom: 20px;
}

.locationTableTheme table tr th {
  box-shadow: none !important;
  /* background: none !important;
  border-radius: 10px !important; */
}

.locationTableTheme table tr {
  border-bottom: 1px solid #d3e1ea;
}

.indicator button {
  width: 20%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.indicatorTheme button {
  width: 20%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.indicator button.Mui-selected {
  background: #16a851;
  border-color: #16a851;
  color: #fff;
}

.indicatorBlue button {
  width: 20%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  background: #d3e1ea;
}

.indicatorBlue button.Mui-selected {
  background: #0f347a;
  border-color: #0f347a;
  color: #fff;
}

.indicatorTheme button.Mui-selected {
  background: #0f347a;
  border-color: #0f347a;
  color: #fff;
}

.locationWrapper {
  margin: 16px;
}

.qrCodeHolder {
  display: flex;
  justify-content: left;
}

.locationBlueBtnHolder {
  display: flex;
  justify-content: flex-end;
}

.locationTable,
.TableCustomm {
  max-width: 100%;
  overflow: auto;
  min-width: 0 !important;
}

.locationTableTheme,
.TableCustomm {
  max-width: 100%;
  overflow: auto;
  min-width: 0 !important;
}

.awardBox {
  width: 23.5%;
  margin: 2% 1.5% 0 0;
  float: left;
  background: #f8f8f8;
}

/* Location Details CSS End */

/* -- Small devices (landscape phones, 768 and up)--*/
@media (max-width: 767.98px) {
  .qrCodeHolder {
    display: flex;
    justify-content: center;
  }

  .qrCodeLocation {
    margin: 10px 10px 0;
  }

  .locationTitleHolder {
    justify-content: center;
  }

  .locationBlueBtnHolder {
    display: flex;
    justify-content: center;
  }

  .rightBlockInbox {
    padding: 10px;
  }

  .rightGridInbox {
    border: none !important;
  }

  .leftcardgrid,
  .rightsideBoxGrid,
  #RightCard,
  .subPlans #leftCard {
    height: auto;
  }

  .leftcardgrid,
  .rightsideBoxGrid {
    margin: 0 15px;
    padding: 0 15px;
  }

  #RightCard,
  .subPlans #leftCard {
    padding-bottom: 20px;
  }

  .subPlans #leftCard .subsamount {
    display: none;
  }

  .inboxWrapper {
    padding: 100px 10px 10px;
  }

  .LeftSideHolder {
    width: 125px;
  }

  main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .inboxSideBar {
    width: 100%;
  }

  .locationTable,
  .TableCustomm {
    max-width: 100%;
    overflow: auto;
    min-width: 0 !important;
  }

  .locationTableTheme,
  .TableCustomm {
    max-width: 100%;
    overflow: auto;
    min-width: 0 !important;
  }

  .accordianLocation {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .locationAccordianTitle {
    font-size: 16px !important;
    width: 100%;
    padding: 13px 10px 13px 45px;
    margin: 0 !important;
  }

  .accordianCeruleanTitle .titleIcon {
    height: 100%;
  }

  .titleIcon {
    left: -16px;
    width: 50px;
    height: 100%;
  }

  .trendingBtnLocation,
  .profileBtnLocation {
    width: 50%;
    float: left;
    padding: 2px;
    text-align: center;
  }

  .locationWrapper {
    margin: 16px 0 !important;
  }

  .locBtnHolder {
    justify-content: center;
    width: 100%;
    margin: 0 !important;
  }

  .followersCounting {
    margin: 15px 0 !important;
    justify-content: left !important;
  }

  .countBoxLoc {
    margin-right: 15px;
  }

  .indicator button {
    width: 50%;
  }

  .indicatorTheme button {
    width: 50%;
  }

  /* .locationBlueBtn {
    width: 260px;
  } */

  .locationBlueBtn button {
    width: 100%;
    padding: 11px 0;
    float: left;
    /* margin: 0 3px 20px 3px; */
    font-size: 0.95em;
    font-weight: bold;
  }
}

/*  Subscription Css End 10-04-2022 */

.tblJobLogo {
  height: 80px;
  width: 50%;
  height: 200px;
  margin-right: 12px;
}

.optInput {
  margin-left: 15px !important;
}

.followerIconPurple {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconPurple::after {
  background: url("../src/images/perpal.png") no-repeat;
  content: "";
  width: 33px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.followerIconBlue {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconBlue::after {
  background: url("../src/images/blue.png") no-repeat;
  content: "";
  width: 33px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.followerIconGreen {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconGreen::after {
  background: url("../src/images/green.png") no-repeat;
  content: "";
  width: 33px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.popularStrip {
  top: -25px;
  left: 13px;
}

/* -- Small devices (landscape phones, 576px and up)--*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .noPaddingSM {
    padding: 0 !important;
  }

  .noMarginSM {
    margin: 0 !important;
  }

  .videoList {
    width: 48%;
  }

  .awardBox {
    width: 48%;
    margin: 2% 1.5% 0 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .followersCounting {
    margin: 15px 0 !important;
    justify-content: left !important;
  }

  .countBoxLoc {
    margin-right: 15px;
  }

  .videoList {
    width: 31%;
  }

  .awardBox {
    width: 31%;
    margin: 2% 1.5% 0 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .qrCodeLocation {
    margin: 15px 15px 15px 100px;
  }
}

/* -- Small devices (landscape phones, 768 and up)--*/
@media (max-width: 767.98px) {
  .graph {
    max-width: 300px;
  }

  .leftDoneBlock {
    height: auto;
  }

  .doneBlock {
    margin: 20px 4% 20px 4%;
    box-shadow: 0 0 15px #d5d5d5 !important;
    max-width: 92%;
  }

  .loginBlock {
    width: 96% !important;
  }

  .loginBlock .loginPanel {
    width: 100%;
    top: 100px;
    position: relative;
    margin-bottom: 130px;
    padding: 20px;
    height: auto;
  }

  .logiLoginHolder .MuiBox-root {
    margin: 0 auto;
  }

  .logiLoginHolder {
    display: block;
    width: 100%;
    margin: 0 auto -15px;
  }

  .logiLoginHolder img {
    display: block;
  }

  .socialMedia {
    margin: 15px -25px 0;
  }
}

/* -- Medium devices (tablets, 768px and up)--*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .leftDoneBlock {
    height: auto;
  }

  .doneBlock {
    margin: 20px 4% 20px 4%;
    box-shadow: 0 0 15px #d5d5d5 !important;
    max-width: 92%;
  }

  .loginBlock {
    width: 96% !important;
  }

  .loginBlock .loginPanel {
    width: 100%;
    top: 100px;
    position: relative;
    margin-bottom: 130px;
    padding: 20px;
    height: auto;
  }

  .logiLoginHolder .MuiBox-root {
    margin: 0 auto;
  }

  .logiLoginHolder {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
  }

  .logiLoginHolder img {
    display: block;
  }
}

/* -- Large devices (desktops, 992px and up)--*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .loginBlock {
    width: 90% !important;
  }

  .loginBlock .loginPanel {
    width: 100%;
    top: 0;
    position: relative;
    margin: 30px 0;
  }

  .logiLoginHolder {
    display: block;
  }
}

@media (max-width: 1199.98px) {
  .headerInner .logiLoginHolder {
    display: none;
  }
}

.fc-day-past {
  background-color: #e5e4e2;
}

.followerCard .followerImg {
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.followerCard .followBackBtn {
  background: #0b4029;
  border: none;
  color: #fff;
  margin-right: 15px;
  font-weight: 500 !important;
  font-size: 15px;
  width: 110px;
  line-height: 1.6;
}

.followerCard .followBackBtn:hover {
  background: #00ab55;
  line-height: 1.6;
}

.followerCard h6 {
  color: #697995;
  font-size: 16px;
}

.countryName {
  font-size: 12px !important;
  color: #697995;
}

.followerItem {
  padding: 20px 20px 0 0;
}

.followerCard .followingBackBtn {
  margin-right: 15px;
  font-weight: 500 !important;
  font-size: 15px;
  width: 110px;
  line-height: 1.6;
  background: #fff;
}

.followerCard .followingBackBtn:hover,
.followerCard .MuiButton-textPrimary {
  background: #f5f5f5;
  line-height: 1.6;
}

.saveSearch {
  display: inline-block !important;
  /* float: left !important; */
  width: 33% !important;
}

.icon-saved {
  position: absolute;
  top: 12px;
  right: 28px;
}

.leftGrid {
  border-right: 1px solid #d3e1ea;
}

.chatIconSize {
  font-size: 30px;
}

.saveSearch {
  display: inline-block !important;
  /* float: left !important; */
  width: 33% !important;
}

.icon-saved {
  position: absolute;
  top: 12px;
  right: 28px;
}

.margin2 {
  margin: 8px;
}

.marginTop2 {
  margin-top: 8px;
}

.margin4 {
  margin: 16px;
}

.marginTop4 {
  margin-top: 16px;
}

/* User Recommendations start */
.margin4 {
  margin: 16px;
}

.margin2 {
  margin: 8px;
}

.card-class {
  height: 79px !important;
  cursor: pointer;
  /* background-color:#BDB4FC !important; */
}

/*  */
.usergalleryContainer {
  grid-auto-rows: auto !important;
  display: block !important;
}

.usergalleryList {
  float: left;
  width: 18%;
  margin-right: 2%;
  margin-bottom: 15px;
  position: relative;
}

.usergalleryList img {
  height: 170px;
}

/*  */
.galleryContainer {
  grid-auto-rows: auto !important;
  display: block !important;
}

.galleryList {
  float: left;
  /* width: 23%; */
  margin-right: 2%;
  margin-bottom: 15px;
  position: relative;
}

.galleryList img {
  height: 170px;
}

.buttonset {
  display: flex;
  gap: 16px;
}

.tableCustom1 {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
}

.tableCustom1 th {
  font-weight: bold;
  /* color: #44577A; */
  padding-left: 15px !important;
}

/* .tableCustom1 td {
  color: #44577A;
} */

.tableCustom1 th,
.tableCustom1td {
  padding: 15px 5px;
  border-bottom: 1px solid #d3e1ea;
  border-left: 1px solid #d3e1ea;
}

.tableCustom1 .table-main-title {
  text-align: initial !important;
}

.jobModelButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.dateBetweenTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* user Recommendations End */
.listclass {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.Grid .orgAlbumListContainer .galleryList {
  width: 31%;
  margin-right: 2%;
  overflow: hidden;
}

.Box .orgAlbumListContainer .galleryList {
  width: 96%;
  margin: 0 2% 2%;
}

.Grid .orgAlbumListContainer .galleryList img {
  height: 200px;
  margin: 0 auto;
  object-position: top;
  object-fit: cover;
  max-height: 230px;
  width: 230px;
}

.Box .orgAlbumListContainer .galleryList img {
  height: 200px;
  margin: 0 auto;
  object-position: top;
  object-fit: cover;
  max-height: 230px;
  width: 100%;
}

/* .orgAlbumListContainer .galleryList img {
  max-height: 170px;
  margin: 0 auto;
} */
.organizerAlbum .Grid {
  display: block !important;
}

.organizerAlbum .Box .audioList {
  width: 96%;
  margin: 0 2% 2%;
}

.organizerAlbum .audioList {
  width: 31%;
  margin-right: 2%;
}

.Grid .orgAlbumListContainer .galleryList {
  width: 31%;
  margin-right: 2%;
  border: 1px solid #d5d5d5;
}

.audioName {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 48px;
  white-space: normal !important;
}

.loginMessage {
  height: 20px;
}

.loginMessage .alertMessage {
  padding: 0 6px;
}

.loginMessage .alertMessage .MuiAlert-message {
  padding: 5px 0;
}

.loginMessage .alertMessage .MuiAlert-icon {
  padding: 4px 0;
}

.subNav {
  position: relative;
  z-index: 99;
}

.albImageHolder span.wrapper {
  overflow: auto;
  max-height: 270px;
}

.CropperSize {
  height: auto !important;
  /* height: 550px !important; */
}

.CropperSize .reactEasyCrop_CropArea {
  /* height: 300px !important;
    width: 350px !important; */
  height: 500px !important;
  width: 400px !important;
  /* height: 550px !important;
    width: 450px !important; */
}

.checkIcon {
  display: none !important;
}

.click-state {
  font-weight: bold;
  color: #3b5999;
  background: #ddd !important;
}

.click-state:hover,
.base-state:hover {
  background: #ddd !important;
}

.base-state {
  font-weight: normal;
  color: #000000;
}

/* .spacing-card-tal{G
        padding-top: 0px !important;
    } */

/* .editButtonDisplayNone .MuiSvgIcon-root{

        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentColor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.5rem;
        display: none !important;

      } */
#canvas {
  border: 10px dashed blue;
}

/* .loginInputHolder {
  position: relative !important;
  margin-bottom: 10px !important;
} */

/* .loginInputHolder p {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 11px;
  margin-left: 0;
} */

/* .confirmPass {
  height: 45px;
} */

/* .confirmPass p {
  position: relative !important;
  left: 0;
  bottom: 0 !important;

} */

.confirm-pass-screen .MuiFormHelperText-contained {
  position: absolute !important;
  left: 0 !important;
  bottom: -35px !important;
}

@media (max-width: 479.99px) {
  .socialMedia a {
    font-size: 14px;
  }
}

.industryListLeft {
  border: 1px solid #d5d5d5;
  margin: 22px 14px !important;
  padding: 0 !important;
  border-radius: 10px;
}

.industryListRight {
  border: 1px solid #d5d5d5;
  margin: 22px 14px !important;
  padding: 0 !important;
  border-radius: 10px;
}

.profileAwards {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.ril__zoomInButton {
  opacity: 1;
  align-items: center;
  display: flex !important;
  justify-content: flex-end !important;
  background-image: unset;
  background-color: transparent;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* .loginInputHolder {
  position: relative !important;
  margin-bottom: 10px !important;
} */

/* .loginInputHolder p {
  position: absolute;
  left: 0;
  bottom: -20px;
} */

@media (max-width: 479.99px) {
  .socialMedia a {
    font-size: 14px;
  }
}

.industryListLeft {
  border: 1px solid #d5d5d5;
  margin: 22px 14px !important;
  padding: 0 !important;
  border-radius: 10px;
}

.industryListRight {
  border: 1px solid #d5d5d5;
  margin: 22px 14px !important;
  padding: 0 !important;
  border-radius: 10px;
}

.profileAwards {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.classBgColor .MuiDialog-paper {
  background-color: #000;
  color: #f5f5f5;
}

.form-check {
  display: flex;
  align-items: center;
}

.box-image {
  display: flex;
  justify-content: center;
}

.box-image .MuiPaper-rounded {
  border-radius: 10px !important;
}

.errorMessage1 {
  /* position: absolute; */
  left: 0px;
  width: 130px;
  top: 40px;
  font-size: 11px;
  color: #c93737;
}

.messageSuccess {
  font-size: 10px;
  position: absolute;
  bottom: -18px;
  color: #00ab55;
  font-weight: normal;
}

.cropProfileImg {
  width: 400px !important;
  height: 500px !important;
}

/* .imgPopOver1 .MuiPopover-paper {
  top: 555px!important;
    left: 321px!important
} */

.box-image-sec button {
  font-weight: 400 !important;
  padding: 6px 9px !important;
  border-radius: 5px !important;
}

.css-h3bten-MuiButtonBase-root-MuiIconButton-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: #000000 !important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.setDrop .css-3zz57v {
  height: 400px !important;
  width: 500px !important;
}

.albModal {
  padding: 16px !important;
  width: 750px !important;
  height: 500px !important;
}

@media (min-width: 1360px) and (max-width: 1700px) {

  .Grid .orgAlbumListContainer .galleryList,
  .videoWrapper .Grid .videoBox {
    width: 23% !important;
    margin-right: 2%;
    overflow: hidden;
  }

  .Box .orgAlbumListContainer .galleryList,
  .videoWrapper .Grid .videoBox {
    /* width: 23%!important; */
    margin-right: 2%;
    overflow: hidden;
  }
}

@media (min-width: 1701px) and (max-width: 2080px) {

  .Grid .orgAlbumListContainer .galleryList,
  .videoWrapper .Grid .videoBox {
    width: 18% !important;
    margin-right: 2%;
    overflow: hidden;
  }

  .Box .orgAlbumListContainer .galleryList,
  .videoWrapper .Grid .videoBox {
    /* width: 18%!important; */
    margin-right: 2%;
    overflow: hidden;
  }
}

.align-title-center {
  display: flex;
  justify-content: center;
}

.enquiryWrapper {
  background: #000;
  /* margin:0 0 50px; */
  padding: 30px;
}

.sendEnquiry {
  font-size: 18px;
  color: #ffffff;
  padding-left: 10%;
}

.sendEnquiryForm {
  padding-left: 10%;
  margin-top: 2%;
}

.enquiryWrapper .MuiOutlinedInput-notchedOutline {
  border-radius: 0% !important;
}

.sendEnquiryForm .MuiInputBase-formControl {
  border-radius: 0;
}

.sendEnquiryForm .MuiInputBase-formControl {
  background: #fff !important;
}

.sendEnquiryForm .MuiFormControl-fullWidth {
  background: none;
}

.sendEnquiryForm .Mui-error {
  margin-left: 0;
  margin-top: 3px;
}

/* db57a6 */

.mainNavJob {
  border: 2px solid #42ab5b;
  border-radius: 5px;
  background-color: #eee !important;
  padding: 6px;
  box-shadow: 0 0 5px #d5d5d5;
}

.activetabjob {
  background-color: #fff;
  color: #000 !important;
  padding: 8px;
  border-radius: 5px;
}

.countTab {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  border-radius: 5px;
  background: #000;
  padding: 2px;
  text-align: center;
  font-weight: normal;
  font-size: 11px;
}

.loginBlock .loginPanel .btnCustom {
  margin-top: 40px !important;
}

.btnCustomDefault {
  box-shadow: none !important;
  min-width: 150px;
  margin-left: 20px;
  margin-top: 22px;
  color: black !important;
  background-color: #fff !important;
}

/* -- Pagination Css--*/
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #009261;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0b4029;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0b4029;
  border-color: #0b4029;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.professionWidth {
  width: 100%;
}

.pencilWithCursorPointer:hover {
  cursor: pointer;
}

.css-1n8pgfw-MuiTableCell-root:first-of-type {
  padding-left: 24px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.css-1n8pgfw-MuiTableCell-root:last-of-type {
  padding-right: 24px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.tableCustom2 {
  /* border: 1px solid #d3e1ea; */
  text-align: left;
}

.tableCustom2 th {
  font-weight: bold;
  color: #44577a;
  /* display: flex;
    justify-content: flex-start; */
  padding-left: 15px !important;
}

.tableCustom2 th,
.tableCustom2 td {
  padding: 15px 5px;
  /* border-bottom: 1px solid #d3e1ea; */
}

.css-16zlzyn-MuiPaper-root-MuiCard-root {
  min-height: 665px !important;
}

.css-unohj8 {
  width: auto !important;
}

.css-1s0p4c5 {
  min-height: 550px !important;
}

.isPrimaryButton Button {
  color: #16a851;
  background-color: #ffffff;
}

.isPrimaryButton Button:hover {
  color: #16a851;
  background-color: #ffffff;
}

.isPrimaryBoxShadow {
  box-shadow: 0 0 10px #444 !important;
}

.selectedTheme {
  box-shadow: 0 0 10px #43e21c !important;
  border: 4px solid #43e21c;
}

.tabBox {
  position: relative;
}

.tabBox .titleIcon {
  left: 0;
  background: url("../src/images/education-icon.png") no-repeat center center #07a5ce;
}

.titleIconInner {
  position: absolute;
  left: 0px;
  width: 65px;
  height: 50px;
  top: 0;
}

.accordianRedTitle .titleIconInner {
  background: url("../src/images/work-exp-icon.png") no-repeat center center #ee4713;
}

.progressPrfileThemeSecond {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 1px;
  color: #581845;
}

.progressPrfileThemeThird {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 1px;
  color: #581845;
}

.accordianOrangeTitle {
  background: #ff754a;
  width: 100%;
}

.accordianOrangeTitle .titleIcon {
  background: url("../src/images/appearance-icon.png") no-repeat center center #ef4713;
}

.accordianBlueSTitle {
  background: #1bc9e4;
  width: 100%;
}

.accordianBlueSTitle .titleIconInner {
  background: url("../src/images/onscreen-performance.jpg") no-repeat center center #07a4cd;
}

.indicatorProfessionTheme .MuiTabs-indicatorTheme {
  background-color: #16a851 !important;
}

.indicatorProfessionTheme {
  background-color: #07a5ce;
  width: 100%;
}

.indicatorProfessionTheme button {
  width: 31%;
  border: 1px solid #d3e1ea;
  margin-left: 5px !important;
  margin-right: 0px !important;
  margin-top: 15px !important;
  border-radius: 50px solid #07a5ce;
  text-transform: uppercase;
  font-weight: bold;
  background: #1bc9e4;
  color: #fff;
}

.indicatorProfessionTheme button.Mui-selected {
  background: #ffffff !important;
  color: #07a5ce !important;
}

.indicatorProfessionTheme .css-1v0hpbg-MuiTabs-indicator {
  background: #ffffff !important;
}

/* .css-17ceore-MuiSvgIcon-root {
  display: none !important;
}

.css-10dohqv {
  display: none !important;
} */

.leftSet {
  left: 0% !important;
}

.MuiSlider-markLabel MuiSlider-markLabelActive css-19haky4-MuiSlider-markLabel {
  left: 0% !important;
}

.isLogoBoxShadow {
  box-shadow: 0 0 10px #d8d7d9 !important;
}

.image-container-main {
  /* width: 400px !important; */
  /* height: 500px !important; */
  /* height: 542px !important; */
  padding: 0px !important;
}

.deactivetab {
  /* padding-top: 8px; */
  margin-top: 8px;
  text-align: center;
}

/* .css-effqtb {
    margin-top: 392px;
    margin-left: 290px;
    border-radius: 50px;
} */

.css-ughypi {
  padding: 0px !important;
}

.isSkillsetBoxShadow {
  box-shadow: 0 0 10px #444 !important;
}

.isSkillsetBoxShadow:focus {
  text-decoration: none !important;
}

.css-1iedms4-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
}

.css-3ettc4 {
  border: none !important;
}

.CalenderCard {
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 10px;
  border: 0.5px solid #c9d7d4;
}

.calenderBox {
  width: 40px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  color: White;
}

.calendergrid {
  margin-left: 10px;
  padding-left: 10px;
  margin-top: 5px;
}

.css-13nt1n8-MuiDialogActions-root {
  background-color: #fff !important;
}

.css-3ct9h1 {
  background-color: #fff !important;
}

.css-zow5z4-MuiGrid-root {
  margin-top: 0px !important;
}

.css-1h77wgb {
  margin-top: 0px !important;
}

.css-dby201 {
  padding-left: 10px !important;
}

.css-1pqm26d-MuiAvatar-img {
  object-fit: unset !important;
}

.css-1hy9t21 {
  object-fit: unset !important;
}

/* .css-6jrdpz {
  object-fit: unset !important;
} */

.css-gia587 {
  min-height: 550px !important;
}

.css-dby201 .wrapper {
  height: 100% !important;
}

.image-container-main .css-1bf5oh2-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  visibility: hidden !important;
}

/* .css-1m495o {visibility: hidden;} */
/* .css-14538h8-MuiStack-root {visibility: hidden !important;} */

.css-14puov7 {
  margin-top: 0px !important;
  margin-bottom: 30px !important;
}

.css-dby201 {
  top: auto !important;
}

.css-1fapizo:hover {
  opacity: 1 !important;
}

.css-1fapizo {
  border-radius: 0 !important;
  background-color: #fff !important;
  border: 0 !important;
}

.css-dby201 {
  border-radius: 0 !important;
}

.agentidGrid {
  border-right: 1px ridge #a8aaad;
}

/* .css-1hy9t21 {
  height: auto !important;
} */
.css-1tmjux7 {
  display: block !important;
}

.image-container-main .css-1w8547d {
  visibility: hidden !important;
}

.educationTraing {
  border: 1px;
  border-radius: 5px;
  margin-top: 2px;
}

.audioHoverMain {
  min-height: 130px;
  transition: all 0.5s ease;
}

.audioHoverMain:hover {
  background: #000000;
  transition: all 0.5s ease;
}

.audioHover {
  display: none;
  margin-left: auto;
  transition: all 0.5s ease;
}

.audioHoverMain:hover .audioHover {
  display: inline-flex;
}

.audioHoverMain:hover .audioHoverData {
  display: none;
  transition: all 0.5s ease;
}

.audioEditIcon {
  height: 50px !important;
  color: #555;
  margin: 10px;
  width: auto !important;
}

.audioEditIcon {
  height: 50px !important;
  color: #555;
  margin: 10px;
  width: auto !important;
}

.videoHover {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  text-align: right;
  padding: 10px;
  transition: all 0.5s ease;
}

.videoTitle {
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: none;
  transition: all 0.5s ease;
}

.selectedThemeProfile {
  box-shadow: 0 0 10px #43e21c !important;
  border: 2px solid #43e21c;
}

.tableworkcredit {
  width: 100%;
  border-color: #a8aaad;
  border-collapse: collapse;
}

.workgrid {
  width: 100%;
  padding-right: 10px;
  min-height: 15px;
}

.companygrid {
  width: 100%;
}

.ResumeLink {
  padding: 0 !important;
}

.videoList:hover .videoTitle {
  display: inline-block;
}

.videoCheckbox {
  position: absolute;
  left: 10px;
  right: 0;
  bottom: 0;
}

.videoCheckbox:hover .videoCheckbox {
  display: inline-block;
}

/* .photoList{

} */

/* .photoList:hover .photoHover{
  display: inline-block;
}

.photoList:hover .photoTitle{
  display: inline-block;
} */

.photoTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.photoBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.default:hover .photoTopIcon {
  display: block;
}

.horizontal:hover .photoTopIcon {
  display: block;
}

.vertical:hover .photoTopIcon {
  display: block;
}

.default:hover .photoBottomIcon {
  display: block;
}

.horizontal:hover .photoBottomIcon {
  display: block;
}

.vertical:hover .photoBottomIcon {
  display: block;
}

.VideoEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.photoEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}



.dashboardCard {
  height: 100%;
  padding: 20px 25px;
}
.dashboarddddd{
  margin-top: -35px !important;
}

.QuillEditor .ql-editor {
  height: 10rem  !important;
}

.ql-editor {
  height: 10rem  !important;
}
.checkBoxSetProfile {
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
  right: 0;
}

.checkboxColor .MuiSvgIcon-root {
  fill: #1baa5a;
}



.photoAlbumTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.photoAlbumBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.albumIcon .photoAlbumBottomIcon {
  display: inline;
}

.PhotoAlbumEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.audioAlbumTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.audioAlbumBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.audioalbumIcon .audioAlbumBottomIcon {
  display: inline;
}

.AudioAlbumEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.AudioAlbumEditIconmobile {
  /* color: "#fff"; */
  height: 20px;
  /* width: 25px; */
  margin-bottom: 15px;
}

.listOfImgHover {
  cursor: pointer;
  position: relative;
}

.iconbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transition: all 0.5s ease;
}

.listOfImgHover:hover .iconbox {
  display: block;
  transition: all 0.5s ease;
}

.databox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transition: all 0.5s ease;
}

.listOfImgHover:hover .databox {
  display: block;
  transition: all 0.5s ease;
}

.listOfImgHoverIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.listOfImgHoverName {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 48px;
  white-space: normal !important;
}

.videoAlbumTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.videoAlbumBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.videoalbumIcon .videoAlbumBottomIcon {
  display: inline;
}

.VideoAlbumEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.VideoAlbumEditIconmobile {
  /* color: "#fff"; */
  height: 20px;
  margin-bottom: 11px;
  /* width: 25px; */
}

.listOfVideoHover {
  cursor: pointer;
  position: relative;
}

.iconVideobox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transition: all 0.5s ease;
}

.listOfVideoHover:hover .iconVideobox {
  display: block;
  transition: all 0.5s ease;
}

.dataVideobox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transition: all 0.5s ease;
}

.listOfVideoHover:hover .dataVideobox {
  display: block;
  transition: all 0.5s ease;
}

.listOfVideoHoverIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.gallarryImg span {
  background: #fff;
}

/* .gallarryImg {
  object-fit: fill !important;
  background: #fff;
 
} */

.css-6jrdpz {
  /* object-fit: scale-down; */
  /* width:fit-content;  */
  /* object-fit: fill !important; */
  /* width: auto !important; */
  height: 100%;
  /* aspect-ratio: 16 / 9; */
}

.css-6jrdpz {
  height: 100%;
  margin: 0 auto;
}

.EditVideo {
  width: 245px;
  height: 155px;
  position: relative;
}

.iconEditVideo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;
}

.EditVideo:hover .iconEditVideo {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  height: 145px;
}

.Audiomodal {
  width: 100% !important;
  margin: none !important;
}

/* changes suggested */
.css-1iedms4-MuiPaper-root-MuiDrawer-paper {
  padding-right: 0px !important;
}

.css-10n7jzh-MuiSlider-root {
  height: 6px !important;
}

.css-1q91lfz {
  height: 6px !important;
}

.css-12jx6y6-MuiListItemIcon-root {
  margin-right: 4px !important;
}

.react-images__view--isModal img {
  margin: 0 auto;
  /* width: 30%; */
}

.AlbumListbtn {
  min-height: 27px;
  max-height: 27px;
  width: 100%;
}

.AlbumListbtn {
  height: 27px;
  width: 100%;
  padding: 0px 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.databoxAlbum {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: none;

  color: #fff;
}

.listOfImgH:hover .databoxAlbum {
  display: block;
  transition: all 0.5s ease;
}

.listOfImgHover:hover .databoxAlbum {
  display: block;
  transition: all 0.5s ease;
}

.default:hover .databoxAlbum {
  display: block;
}

.horizontal:hover .databoxAlbum {
  display: block;
}

.vertical:hover .databoxAlbum {
  display: block;
}

/* .css-labmu-MuiList-root {
  width: max-content;
  display: inline-flex;
} */
.css-labmu-MuiList-root {
  display: inline-block !important;
  width: 100% !important;
}

.css-1yxwikt-MuiGrid-root {
  display: inline-block !important;
  width: auto !important;
}

.css-396wsb {
  display: inline-block !important;
  width: 100% !important;
}

.css-9f1g7i {
  display: inline-block !important;
  width: auto !important;
}

.videoList {
  width: 260px !important;
}

.videoList span svg {
  fill: #ffffff !important;
}

.tableRecomStatus {
  text-align: left;
  border: 2px solid #009261;
  border-radius: 10px !important;
  border-collapse: collapse;
}

.calenderTd {
  padding: 0px !important;
  margin: 0px !important;
}

.ImggallarryImg {
  object-fit: cover !important;
  background: #fff;
}

.PrivatePickersToolbar-dateTitleContainer .css-hrm44d-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

.PrivatePickersToolbar-dateTitleContainer .PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.PrivatePickersToolbar-dateTitleContainer .css-1gwtl1m {
  display: none !important;
}

.css-w4xala {
  /* padding: 80px 16px !important; */
  /* padding: 95px 16px !important; */
  padding: 115px 16px !important;
  transition: margin-left 200ms cubic- bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-1qm1lh {
  margin-bottom: 8px !important;
}

.css-18xzqic-MuiPaper-root-MuiDrawer-paper {
  border-right-style: none !important;
}

.css-4ugqq4 {
  border-right-style: none !important;
}

.css-1sjqapr-MuiPaper-root-MuiDrawer-paper {
  border-right-style: none !important;
  border-right: #44577a !important;
  background-color: #44577a !important;
}

.css-1porhrx {
  border-right-style: none !important;
  border-right: #44577a !important;
  background-color: #44577a !important;
}

.css-1v0hpbg-MuiTabs-indicator {
  height: 2px !important;
  bottom: 6px !important;
}

.css-ee9i3f {
  height: 2px !important;
  bottom: 6px !important;
}

.SeekBtn {
  background-color: #d4e1e9 !important;
  color: #505b62 !important;
}

.BrowseImage.css-6jrdpz {
  height: auto !important;
  max-width: 100% !important;
  margin: 0 !important;
}

/* ---------------------------ad----------------------------------- */

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* main.css-w4xala {
  width: 100%;
} */

.imgupload-container {
  overflow: visible !important;
}

table.table-striped.tableCustom2 {
  width: 100%;
  /* border: 1px solid #d3e1ea; */
  border-radius: 8px;
}

table.table-striped>tr:nth-of-type(odd)>* {
  background-color: #f4f9fb;
}

table.table-striped.tableCustom2.tableBorder>tr:first-child>th {
  border-radius: 8px 0 0 0;
  /* border-radius: 0px; */
  border-top: 1px solid #d3e1ea;
}

table.table-striped.tableCustom2.tableBorder>tr:last-child>th {
  border-radius: 0 0 0 8px;
  /* border-radius: 0px; */
  border-bottom: 1px solid #d3e1ea;
}

table.table-striped.tableCustom2.tableBorder>tr:first-child>td {
  border-radius: 0 8px 0 0;
  /* border-radius: 0px; */
  border-top: 1px solid #d3e1ea;
}

table.table-striped.tableCustom2.tableBorder>tr:last-child>td {
  border-radius: 0 0 8px 0;
  /* border-radius: 0px; */
  border-bottom: 1px solid #d3e1ea;
}

table.table-striped.tableCustom2.tableBorder>tr>th {
  border-left: 1px solid #d3e1ea;
}

table.table-striped.tableCustom2.tableBorder>tr>td {
  border-right: 1px solid #d3e1ea;
}

#galleryPortfoliProfile .MuiStack-root a img,
.dashboardPortfolioImageGallery a img {
  object-fit: initial !important;
}

.portfolioGalleryImagesContainer {
  width: 30%;
}

.dashboardPortfolioImageGallery {
  width: 55%;
}

.imageUploadBtnProfilepage {
  background: #ffffff91;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

@media only screen and (max-width: 768px) {
  table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .sliderplaying--age .MuiBox-root.css-0 .MuiSlider-marked .MuiSlider-markLabel {
    font-size: 0.59rem;
  }

  .agentidGrid {
    border-right: 0;
    border-bottom: 1px solid #a8aaad;
  }

  .portfolioGalleryImagesContainer {
    width: 100%;
  }

  .loginBlock {
    padding-bottom: 2rem;
  }

  .dashboardPortfolioImageGallery {
    width: 100%;
  }

  .imageUploadBtnProfilepage {
    right: 7%;
    bottom: 17%;
  }
}

#loginSliderContainer {
  padding: 0 !important;
  height: 100vh;
  position: relative;
}

#overlay {
  width: 90%;
  position: absolute;
  top: 14%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

.loginSwiper {
  height: 100vh;
}

.loginSwiper .slick-dots {
  display: none;
}

.loginSwiper .slick-arrow {
  display: none !important;
}

.slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* 
#slide1 {
  background-image: url(./images/slider1.jpg);
}

#slide2 {
  background-image: url(./images/slider2.png);
}

#slide3 {
  background-image: url(./images/slider3.png);
} */

.loginH1 {
  font-size: 1.1rem !important;
}

.para {
  font-size: 0.8rem !important;
}

.para,
.loginH1 {
  color: #00ab55;
}

#loginSliderContainer .loginSwiper .slick-arrow.slick-prev {
  z-index: 2;
  left: 2%;
}

#loginSliderContainer .loginSwiper .slick-arrow.slick-next {
  z-index: 2;
  right: 2%;
}

.css-1yogdcs-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  padding: 0.375rem 0.75rem !important;
}

.imageUploaderProfilePageBox {
  position: absolute;
  bottom: 4%;
  right: 6%;
}

@media only screen and (max-width: 992px) {
  div#editBtnProfileedit {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  /* .imageUploadBtnProfilepage {
    right: 10%;
    bottom: 10%;
  } */
}

/* -------------------------class added */

.image-container-main.MuiStack-root.MuiBox-root.css-ughypi.MuiBox-root.css-0button {
  display: none !important;
}

.css-14puov7 {
  /* min-height: 450px !important; */
  min-height: 400px !important;
}

.profileCompleteBar {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.2rem;
}

/* .css-1m495o {
  display: none !important;
} */

.profileLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container-main button.MuiButtonBase-root {
  display: none;
}

/* ---------- */

#loginSliderContainer,
#RegSliderContainer {
  padding: 0 !important;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}

#overlay {
  width: 90%;
  position: absolute;
  top: 4%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

.loginSwiper {
  height: 100vh;
}

.loginSwiper .slick-dots {
  display: none !important;
}

.slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide1 {
  background-image: url(./images/slider1.jpg);
}

#slide2 {
  background-image: url(./images/slider2.png);
}

#slide3 {
  background-image: url(./images/slider3.png);
}

.loginH1 {
  font-size: 1.1rem !important;
}

.para {
  font-size: 0.8rem !important;
}

.para,
.loginH1 {
  color: #00ab55;
}

#loginSliderContainer .loginSwiper .slick-arrow.slick-prev {
  z-index: 2;
  left: 2%;
}

#loginPageLogoContainer {
  width: 60%;
}

#loginLeft,
#loginRight {
  height: 100%;
}

#Loginfooter {
  position: absolute;
  bottom: 0%;
  left: 1.1%;
  display: flex;
  align-items: center;
}

#loginPage .logiLoginHolder {
  margin-left: 0rem;
}

#Loginfooter ul {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#Loginfooter ul li {
  margin: 0.5rem;
  color: #00ab55;
}

#Loginfooter ul li a {
  color: #00ab55;
}

#Loginfooter img[alt="logo"] {
  width: 100%;
  margin-left: 3rem;
}

header img[alt="logo"] {
  width: 100%;
  margin-left: 3rem;
}

@media only screen and (max-width: 768px) {
  table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .sliderplaying--age .MuiBox-root.css-0 .MuiSlider-marked .MuiSlider-markLabel {
    font-size: 0.59rem;
  }

  .agentidGrid {
    border-right: 0;
    border-bottom: 1px solid #a8aaad;
  }

  .portfolioGalleryImagesContainer {
    width: 100%;
  }

  .loginBlock {
    padding-bottom: 2rem;
  }

  .dashboardPortfolioImageGallery {
    width: 100%;
  }

  .imageUploadBtnProfilepage {
    right: 7%;
    bottom: 17%;
  }

  .para {
    display: none;
  }

  #loginLeft,
  #loginRight {
    height: auto;
  }

  #overlay {
    top: 12%;
    width: 80%;
  }

  #loginPage .logiLoginHolder {
    margin-left: 0rem;
  }

  #Loginfooter {
    bottom: -0.5%;
    flex-direction: column;
  }

  #Loginfooter ul {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }

  #Loginfooter ul li {
    margin: 0.25rem;
  }

  #Loginfooter #loginPageLogoContainer,
  header #loginPageLogoContainer {
    margin-bottom: 0.8rem;
  }

  #RegPage #loginSliderContainer {
    height: auto;
    overflow-y: visible;
  }

  #RegPage #loginSliderContainer .loginH1 {
    font-size: 1rem !important;
  }

  #RegPage #loginSliderContainer input {
    padding: 0.8rem !important;
  }

  #Loginfooter img[alt="logo"] {
    width: 100%;
    margin-left: 0;
  }

  header img[alt="logo"] {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  #Loginfooter {
    flex-direction: column;
    align-items: center;
  }

  #loginPage .logiLoginHolder {
    margin-left: -5rem;
  }

  #Loginfooter img[alt="logo"] {
    width: 75%;
    margin-left: 0;
  }

  header img[alt="logo"] {
    width: 75%;
    margin-left: 0;
  }
}

/* --------------------------------------------------------------------------------------------------- */

#LoginPageNew .slick-arrow {
  z-index: 2;
}

#LoginPageNew .slick-next {
  right: 1.5rem !important;
}

#LoginPageNew .slick-prev {
  left: 1.5rem !important;
}

#LoginPageNew .slick-list {
  height: 100vh;
}

#overlay {
  width: 90%;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  min-height: 100vh;
}

#overlay footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px soild yellow;
}

#overlay header a img.logoImage {
  width: 13%;
}

#overlay footer a img.logoImage {
  width: 60%;
}

.loginH1 {
  font-size: 1.5rem !important;
  letter-spacing: 0 !important;
}

.para {
  font-size: 0.625rem !important;
}

#overlay footer #footerMenu ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#overlay footer #footerMenu ul li a,
#overlay footer #footerMenu ul li {
  color: #00ab55;
}

#overlay footer #footerMenu ul li a:hover,
#overlay footer #footerMenu ul li:hover {
  color: #ffc300;
}

#overlay footer #footerMenu ul li {
  margin: 0.25rem;
  padding: 0.25rem;
}

#overlay footer #footerMenu ul li a {
  text-decoration: none;
}

#overlay footer {
  display: flex;
  align-items: center;
}

.resetHead {
  font-size: 1.8rem !important;
}

.slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide1 {
  background-image: url(./images/slider1.jpg);
}

#slide2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(./images/slider2.png);
}

#slide3 {
  background-image: url(./images/slider3.png);
}

div#outlined-select-currency {
  padding: 0.8rem !important;
}

@media only screen and (max-width: 768px) {
  #overlay {
    width: 82%;
  }

  #LoginPageNew .slick-next {
    right: 0.5rem !important;
  }

  #LoginPageNew .slick-prev {
    left: 0.5rem !important;
  }

  .loginH1 {
    font-size: 1.1rem !important;
  }

  #overlay header a img.logoImage {
    width: 60%;
  }

  #overlay footer a img.logoImage {
    width: 55%;
  }

  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenu ul {
    flex-wrap: wrap-reverse;
  }

  #overlay footer #footerMenu ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }

  .reg .loginH1 {
    font-size: 1rem !important;
  }

  .resetHead {
    font-size: 1.5rem !important;
  }

  .resetConfPass #overlay header img {
    width: 50% !important;
  }

  .resetConfPass #overlay footer img {
    width: 40% !important;
  }

  .resetConfPass #overlay .loginH1 {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenu ul {
    flex-wrap: wrap-reverse;
  }

  #overlay footer #footerMenu ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }
}

#LoginPageNew .slick-arrow,
#RegPageNew .slick-arrow {
  z-index: 2;
}

#LoginPageNew .slick-next,
#RegPageNew .slick-next {
  right: 1.5rem !important;
}

#LoginPageNew .slick-prev,
#RegPageNew .slick-prev {
  left: 1.5rem !important;
}

#LoginPageNew .slick-list {
  height: 100vh;
}

#RegPageNew .slick-list {
  height: 130vh;
}

#overlay {
  width: 90%;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  min-height: 100vh;
}

#RegPageNew #overlay {
  min-height: 130vh;
}

#overlay footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px soild yellow;
}

#overlay header a img.logoImage {
  width: 13%;
}

#overlay header a img.logoImage {
  width: 100% !important;
}

#overlay footer a img.logoImage {
  width: 60%;
}

.loginH1 {
  font-size: 1.5rem !important;
  letter-spacing: 0 !important;
}

.para {
  font-size: 0.625rem !important;
}

#overlay footer #footerMenu ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#overlay footer #footerMenu ul li a,
#overlay footer #footerMenu ul li {
  color: #00ab55;
}

#overlay footer #footerMenu ul li a:hover,
#overlay footer #footerMenu ul li:hover {
  color: #ffc300;
}

#overlay footer #footerMenu ul li {
  margin: 0.25rem;
  padding: 0.25rem;
}

#overlay footer #footerMenu ul li a {
  text-decoration: none;
}

#overlay footer {
  display: flex;
  align-items: center;
}

.resetHead {
  font-size: 1.8rem !important;
}

.slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#RegPageNew .slide {
  height: 130vh;
}

#slide1 {
  background-image: url(./images/slider1.jpg);
}

#slide2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(./images/slider2.png);
}

#slide3 {
  background-image: url(./images/slider3.png);
}

div#outlined-select-currency {
  padding: 0.8rem !important;
}

.regBtn {
  margin-top: 3rem;
}

@media only screen and (max-width: 768px) {
  #overlay {
    width: 100%;
    padding: 1rem;
  }

  #LoginPageNew #LoginPageNewBox {
    height: 120vh;
  }

  #RegPageNew #RegPageNewBox {
    height: 170vh;
  }

  #LoginPageNew .slick-list {
    height: 120vh;
  }

  #RegPageNew .slick-list {
    height: 170vh;
  }

  #LoginPageNew .slide {
    height: 120vh;
  }

  #RegPageNew .slide {
    height: 170vh;
  }

  #RegPageNew #overlay {
    /* height: 170vh; */
    min-height: 170vh;
  }

  #LoginPageNew .slick-next,
  #RegPageNew .slick-next {
    right: 0.5rem !important;
  }

  #LoginPageNew .slick-prev,
  #RegPageNew .slick-prev {
    left: 0.5rem !important;
  }

  .loginH1 {
    font-size: 1.1rem !important;
  }

  #overlay header a img.logoImage {
    width: 60%;
  }

  #overlay footer a img.logoImage {
    width: 55%;
  }

  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenu ul {
    flex-wrap: wrap-reverse;
  }

  #overlay footer #footerMenu ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }

  .reg .loginH1 {
    font-size: 1rem !important;
  }

  .resetHead {
    font-size: 1.5rem !important;
  }

  .resetConfPass #overlay header img {
    width: 50% !important;
  }

  .resetConfPass #overlay footer img {
    width: 40% !important;
  }

  .resetConfPass #overlay .loginH1 {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenu ul {
    flex-wrap: wrap-reverse;
  }

  #overlay footer #footerMenu ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 100vh;
  }

  #RegPageNew .slide {
    height: 100vh;
  }

  #RegPageNew #overlay {
    min-height: 100vh !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  #LoginPageNew #LoginPageNewBox {
    height: 130vh;
  }

  #LoginPageNew .slick-list {
    height: 130vh;
  }

  #LoginPageNew .slide {
    height: 130vh;
  }

  #RegPageNew #RegPageNewBox {
    height: 170vh;
  }

  #RegPageNew .slick-list {
    height: 170vh;
  }

  #RegPageNew .slide {
    height: 170vh;
  }

  #RegPageNew #overlay {
    min-height: 170vh !important;
  }
}

/* --------------------------------------30032023ad------------------------------------------ */

.appliedJobBox h6,
.appliedJobBox p {
  font-size: 0.875rem !important;
}

.appliedJobBox table th {
  text-align: left;
}

.appliedJobBox table th,
.appliedJobBox table td,
#jobpostmodaltable table th,
#jobpostmodaltable table td,
#appliedJobDetail table th,
#appliedJobDetail table td {
  padding: 0.5rem 1rem;
}

#appliedJobDetail .table-responsive::-webkit-scrollbar {
  display: none;
}

.chatHeader {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid;
}

.chatInput {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid;
}

.messageArea {
  height: 68%;
  position: absolute;
  top: 14.7%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.messageArea::-webkit-scrollbar {
  display: none;
}

.sender {
  align-self: flex-end;
  background-color: #4b8bff !important;
  max-width: 55%;
}

.sender p {
  color: #fff;
}

.receiver {
  align-self: flex-start;
  background-color: #d3e1ea !important;
  max-width: 55%;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.img-fluid {
  max-width: 100% !important;
  height: auto !important;
}

.img-fluids {
  width: 85px !important;
  height: 70px !important;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.recentEmpViewSlider .slick-arrow {
  z-index: 1;
  top: 80%;
}

.recentEmpViewSlider .slick-arrow.slick-prev {
  left: 5% !important;
  color: #fff !important;
}

.recentEmpViewSlider .slick-arrow.slick-next {
  right: 5% !important;
  color: #fff !important;
}

.recentEmpViewSlider .slick-slide {
  max-height: 11rem !important;
}

.jobLikedSliderCon {
  margin-left: auto;
  margin-right: auto;
}

.jobLikedSliderCon .slick-arrow {
  z-index: 1;
}

.jobLikedSliderCon .slick-arrow.slick-prev {
  /* left: -17% !important; */
  left: -38px !important;
  top: 50%;
}

.jobLikedSliderCon .slick-arrow.slick-next {
  /* right: -21% !important; */
  right: -48px !important;
  top: 50%;
}

.jobLikedSliderCon .slick-arrow.slick-next::before,
.jobLikedSliderCon .slick-arrow.slick-prev::before {
  color: #000;
}

.profilePortfolioCropperDia .MuiDialog-paper {
  width: 100%;
  max-width: 500px;
}

.selectedPortImage {
  border: 2px solid #1aa357;
  padding: 2px;
}

#Loginfooter img[alt="logo"] {
  width: 100%;
  margin-left: 0rem;
}

header img[alt="logo"] {
  width: 100%;
  margin-left: 0rem;
}

@media only screen and (max-width: 768px) {
  .navbar-toggle {
    margin-left: 0rem !important;
  }
}

.selected {
  border: 2px solid #1aa357;
  padding: 2px;
}

.dashPageIcon {
  font-size: 3.2rem;
}

.qr {
  width: 4rem !important;
  height: 4rem !important;
}

.userNewFormUploader {
  position: absolute;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive::-webkit-scrollbar {
  display: none !important;
}

.jobSeekData .MuiGrid-container:last-child {
  border: 0 !important;
}

.imageSelector svg {
  color: #fff;
}

.agentCard p,
.agentCard h6 {
  word-break: break-all;
}

@media only screen and (max-width: 768px) {
  .jobLikedSliderCon .slick-arrow.slick-prev {
    left: -17% !important;
  }

  .jobLikedSliderCon .slick-arrow.slick-next {
    right: -17% !important;
  }

  .mainNavJob {
    padding: 0;
  }

  .mainNavJob a {
    border-radius: 0rem;
  }

  /* main{
    width:100%;
  }
  main.css-w4xala {
    width: 100%;
  } */
}

.agentCard {
  min-height: 300px;
}

.imageSelector svg {
  color: #fff;
}

/* ----------------------120420231013----------------------------- */
.circle {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  overflow: hidden;
}

.text-white {
  color: #fff !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.profileInsight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profileInsight h6 {
  line-height: 1 !important;
}

.profileInsight span {
  line-height: 1 !important;
  font-size: 0.7rem !important;
}

.Tab .MuiListItemIcon-root {
  min-width: 2rem !important;
}

.tabHome {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.TabTitle {
  color: #0f347a;
  position: relative;
}

.TabTitle::after {
  content: "";
  width: 4rem;
  height: 0.188rem;
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: #ff754a;
}

.circle.profileIntroTab {
  height: 4.5rem;
  width: 4.5rem;
}

.EduTable table thead th {
  padding: 0.5rem;
  font-weight: 400;
  text-align: left;
  font-size: 0.83rem;
}

.EduTable table,
.paTable table {
  border: 1px solid #d3e1ea;
}

.EduTable table tr td {
  border-bottom: 1px solid #d3e1ea;
  color: #44577a;
  font-weight: 600;
}

.EduTable table tr:last-child td {
  border-bottom: 0;
}

.paTable table th {
  text-align: left;
  color: #44577a;
}

#radioTypeForProfession .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-toggle svg {
  font-size: 1.8rem;
}

/* .fixed-bottom-update-btn {
  margin-top: 1rem;
} */

.font-weight-bold {
  font-weight: 600 !important;
}

@media only screen and (max-width: 1199px) {
  .sidebar-user-info {
    padding: 0;
  }

  .sidebar-user-info div.MuiPaper-root.MuiPaper-elevation:first-child {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .headerInner .header-logo-container {
    margin-top: 0;
    position: absolute;
    /* left: 50%; */
    left: 47%;
    right: 50%;
    transform: translate(-66%, 0%);
    width: 10rem;
  }

  .headerInner .header-logo-container img {
    width: 85%;
  }

  .profileCompleteBar {
    justify-content: flex-start;
  }

  .fixed-bottom-update-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;

    background-color: #fff;
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .eduCard .MuiGrid-item p {
    word-break: break-all;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .profileCompleteBar {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar-user-info {
    display: none !important;
  }
}

/* #pdfResumeDialog .MuiDialog-container .MuiPaper-root {
  min-width: 992px;
  width: 100%;
  height: 100vh;
  overflow-y: auto !important;
} */

#pdfResumeDialog .MuiDialog-container .MuiPaper-root .MuiDialogContent-root iframe {
  width: 100%;
  height: 100%;
}

.folderMenu li {
  display: block;
  width: 100%;
}

.folderMenu li a {
  display: block;
}

@media only screen and (max-width: 992px) {
  .fixed-bottom-update-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
    display: flex;
    justify-content: flex-end;
    z-index: 99;
  }
}

@media only screen and (max-width: 768px) {
  #playingAgeSlider .css-d8ky90-MuiSlider-markLabel {
    font-size: 0.625rem !important;
    white-space: pre-wrap;
  }

  #playingAgeSlider .css-19haky4-MuiSlider-markLabel {
    font-size: 0.7rem !important;
  }
}

.themecardBtn {
  position: absolute;
  bottom: 6%;
  right: -4%;
  z-index: 1;
}

/* -----------------------28-04-2023----------------------------- */

.jobSeekHeader {
  min-height: 5.5rem;
  background-image: linear-gradient(to left,
      #025437 33%,
      #b5dbc7 95%,
      #ffffff 101%) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.25rem 0.3rem rgba(0, 0, 0, 0.25) !important;
}

.text-center {
  text-align: center !important;
}

.myProjectCard {
  min-height: 500px;
}

.sender {
  background-color: #025437 !important;
}

.receiver {
  background-color: #edfff9 !important;
}

.sky.CircularProgressbar .CircularProgressbar-path {
  stroke: #7fdbec !important;
}

.green.CircularProgressbar .CircularProgressbar-path {
  stroke: #36b37e !important;
}

.yellow.CircularProgressbar .CircularProgressbar-path {
  stroke: #ffab00 !important;
}

.sky.CircularProgressbar .CircularProgressbar-text,
.text-sky svg {
  color: #7fdbec !important;
}

.green.CircularProgressbar .CircularProgressbar-text,
.text-green {
  color: #36b37e !important;
}

.yellow.CircularProgressbar .CircularProgressbar-text,
.text-yellow {
  color: #ffab00 !important;
}

#myProjectTimeline .react-calendar-timeline {
  border: 1px solid #bbb;
  border-radius: 1rem;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root {
  border-radius: 1rem 1rem 0 0;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root div:first-child {
  color: #000 !important;
  display: flex;
  align-items: flex-end;
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  border-radius: 1rem 0 0 0;
  padding: 1rem;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header {
  border-radius: 0rem 1rem 0 0 !important;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root {
  background-color: #fff !important;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header div:first-child {
  display: none;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header div:last-child {
  height: 100% !important;
}

#myProjectTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header .rct-dateHeader span {
  font-size: 0.7rem !important;
}

#myProjectTimeline .react-calendar-timeline .rct-horizontal-lines div:last-child,
#myProjectTimeline .react-calendar-timeline .rct-sidebar div div.rct-sidebar-row:last-child {
  border-bottom: 0 !important;
}

/*-----job seek------------------*/
.jobSeekHeader {
  min-height: 5.5rem;
  background-image: linear-gradient(to right,
      #ffffff 12%,
      #024d2a 100%) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.25rem 0.3rem rgba(0, 0, 0, 0.25) !important;
}

.professionCardHeader {
  background: linear-gradient(to right, #ffffff 12%, #024d2a 100%) !important;
}

.incompleteprofessionCardHeaderMobile {
  background: #dc3545 !important;
}

.incompleteprofessionCardHeader {
  background: linear-gradient(to right, #ffffff 12%, #dc3545 100%) !important;
}

.text-dark-green {
  color: #024d2b;
}

.text-dark-grey {
  color: #383838;
}

/* ============================================================ */

.ospBtns {
  /* padding: 0.5rem !important; */
  border-radius: 0.5rem !important;
}

/* .loginInputHolder p {
  position: absolute;
  left: 0;
  bottom: -33px;
} */

#themeProfileNew {
  position: absolute;
  bottom: 2%;
  right: 2%;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

/* -------------------03-05-2023------------------------- */

#pASlider .MuiSlider-rail {
  background-color: #cccccc !important;
  /* height: 0.55rem !important; */
}

#pASlider .MuiSlider-track {
  background-color: #0a5429 !important;
}

.css-16ng260-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #0a5429 !important;
  border: 2px solid #0b4029 !important;
}

span.MuiSwitch-track.css-7qr9is-MuiSwitch-track {
  border: 2px solid #0b4029 !important;
}

/* .photoTopIcon.show {
  display: block;
} */

/* .photoTopIcon.hide {
  display: none;
} */

.eduTables table thead th,
.workCreditTable table th {
  font-weight: 400;
  background-color: #fff;
}

.eduTables table,
.workCreditTable table {
  border-collapse: separate !important;
  border-spacing: 0rem 1rem;
  border-radius: 0.5rem;
}

.eduTables table tbody td,
.workCreditTable table tbody td {
  font-weight: 600;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f8f8f8;
  color: #44577a;
}

.eduTables table tbody td:first-child,
.workCreditTable table tbody tr td:first-child {
  border-left: 1px solid #dbdbdb;
  border-radius: 0.5rem 0 0 0.5rem;
}

.eduTables table tbody td:last-child,
.workCreditTable table tbody tr td:last-child {
  border-right: 1px solid #dbdbdb;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

/* ---------------- */

.bannerText {
  position: absolute;
  right: 15%;
  top: 50%;
}

/* ---------------------040520231211------------------ */

.subscribePlanDash .CircularProgressbar-trail {
  stroke: #ddd !important;
}

.subscribePlanDash .activePlanProgText {
  position: absolute;
  top: 13%;
}

.videoList:hover .videoHover {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

a:hover>.videoHover {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.photoTopIcon.hide,
.videoHover.hide {
  display: none;
}

.photoTopIcon.show {
  display: block;
}

.videoHover.show {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.photoTopIcon.show .VideoEditIcon,
.videoHover.show .VideoEditIcon {
  display: none;
}

a:hover>.photoTopIcon.hide .VideoEditIcon,
a:hover>.videoHover.hide .VideoEditIcon {
  display: block !important;
}

.photoBottomIcon.show,
.videoTitle.show {
  display: none !important;
}

.audioHoverMain.selected .audioHover {
  display: flex;
}

/* ------------080520231316--------------------- */

/* .themePublishActions {
  position: absolute;
  top: -5.6%;
  right: 12%;
  z-index: 9999;
} */

.tBannerText {
  position: absolute;
  top: 23%;
  left: 9%;
}

.cropperBtn {
  position: absolute;
}

#themeScreen>div:first-child .image-container-main input+div {
  display: none;
}

#themeScreen>div:first-child .image-container-main input+div+div span {
  padding: 0;
  border-radius: 0;
}

@media only screen and (max-width: 768px) {
  .tBannerText {
    position: static;
    padding: 1rem;
  }
}

/* -----------------090520231008------------------------------ */

.tBannerText07 {
  position: absolute;
  bottom: 3%;
  left: 2%;
}

.themeCropper form .image-container-main input+div {
  display: none;
}

.themeCropper form .image-container-main div:last-child {
  border-radius: 50%;
}

.themeCropper form .image-container-main div:last-child span span img {
  display: none;
}

.themeCropper .imageUploaderProfilePageBox {
  position: static;
}

.themeCropper form .image-container-main input+div+div span:first-child {
  padding: 0;
  background: transparent;
}

.audioHover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #1414149f;
  display: none;
  transition: all 0.2s ease;
}

.audioHover .audioEditIcon {
  height: 30px !important;
  color: #fff;
  margin: 10px;
  width: auto !important;
}

.audioHover span input+svg {
  color: #fff;
}

.audioHoverMain:hover {
  background-color: transparent !important;
}

.audioHoverMain:hover .audioHoverData {
  display: block !important;
}

.audioHoverMain:hover .audioHover {
  display: flex;
}

.audioHover.hide {
  display: none;
}

.audioHover.show {
  display: flex;
}

.audioHover.show .audioEditIcon {
  display: none;
}

.audioHoverMain.selected .audioHoverData {
  display: block !important;
}

@media only screen and (max-width: 768px) {
  .tBannerText07 {
    bottom: 25%;
  }
}

/* ---------------14042023---------------------------------------- */

.profilePageHeaderLogo {
  width: 85%;
}

.profilePageHeaderNav {
  display: flex;
  justify-content: flex-end;
}

#profilePageHeader .profilePageHeaderNav ul li {
  width: fit-content !important;
}

#profilePageHeader .profilePageHeaderNav ul li .MuiListItemText-root span {
  font-weight: 600 !important;
}

.profilepagehero {
  width: 100%;
  height: 70vh;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profilePageBannerTextCont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profilePageBannerTextCont .MuiBox-root:first-child {
  padding-right: 1rem;
}

.profile05BannerText {
  position: absolute;
  right: 5%;
  bottom: 5%;
}

#profile7Skill .css-dixzs8-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  background-color: #eee !important;
}

#profilePage010skills .css-dixzs8-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  background-color: #256d85;
}

.rotate {
  transform: rotate(270deg);
}

@media only screen and (max-width: 768px) {
  .profilePageHeaderLogo {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .profilePageHeaderNav {
    justify-content: center;
  }

  .rotate {
    transform: rotate(0deg);
  }
}

/* ---------------------150520231116-------------------- */

.MuiLink-underlineHover {
  padding: 0 !important;
}

#overlay.resetCPAss {
  min-height: 130vh;
}

#pASlider .css-ovj1yd-MuiSlider-mark {
  width: 0 !important;
  height: 0 !important;
}

@media only screen and (max-width: 768px) {
  #overlay.resetCPAss {
    min-height: 120vh;
  }
}

.css-gcm6c9-MuiSlider-valueLabel {
  background-color: #0a5434 !important;
}

.css-1f11fhg-MuiSlider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(11, 64, 41, 0.16);
}



.ProperSkillVal {
  display: flex;
  flex-wrap: wrap !important;
}

#errorDate .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #ff4a42 !important;
}


.css-1hxsw79 .fc .fc-event .fc-event-main {
  display: block !important;
  background-color: transparent !important;
}

.css-1hxsw79 .fc .fc-event .fc-event-main:before {
  border: 0 !important;
}

.css-1hxsw79 .fc .fc-event .fc-event-main-frame {
  filter: none !important;
  overflow: hidden !important;
}

.css-1hxsw79 .fc .fc-event .fc-event-main:after {
  background-color: transparent !important;
}


.css-8bl6v6 {
  padding-bottom: 0rem;
}

/* -----------------------220520231127------------------ */

#pdfResumeDialog .MuiDialog-container .MuiPaper-root .MuiDialogContent-root iframe {
  width: 0px !important;
  height: 0px !important;
}

.css-8bl6v6 {
  padding-bottom: 0rem !important;
}

/* -----------240520231324----------------adi */

.css-10n7jzh-MuiSlider-root {
  width: 98% !important;
}

.css-1q91lfz {
  width: 98% !important;
}

.MuiSlider-thumb[data-index="1"],
.MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabelOpen {
  background-color: #42ab5b !important;
}

.MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabelOpen {
  transform: rotate(180deg) !important;
}

.MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabelOpen .MuiSlider-valueLabelCircle {
  transform: rotate(180deg) !important;
}

.locationBlueBtnTheme button {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.calenderBox {
  width: 35px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  color: White;
}



.profile05BannerText1 {
  position: absolute;
  right: 20%;
  bottom: 20%;
}

.profilePageBannerTextCont1 {
  display: flex;
  justify-content: center;
  align-items: center;
}





.mainNavJob::-webkit-scrollbar {
  margin-top: 0.1px;
}

.dashboardCard {
  height: 100%;
  /* padding: 20px 25px; */
}

.qr {
  width: 3.5rem !important;
  height: 4rem !important;
}

.edutraining-fieldset {
  border-color: rgba(204, 204, 204, 0.27);
  border-top-left-radius: 12px;
  border-radius: 10px;
}

.mainNav::-webkit-scrollbar {
  margin-top: 5px;
  width: 0.1em;
  background-color: transparent;
  display: none;
}

.messageArea::-webkit-scrollbar {
  width: 3px;
}

.messageArea::-webkit-scrollbar-thumb {
  background-color: #025437;
  height: 10px;
}

.messageArea::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.inboxrightpannelcard {
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -18px;
}

.CardLeftSide {
  position: relative;
  width: 100%;
  text-align: left;
}

.CardLeftSide img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
}

.rightpannelcard {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  /* padding: 10px 100px 10px 110px; */
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
  /* padding: 3rem 1rem; */
  padding: 3rem 1rem;
}

.IconBox {
  position: absolute;
  cursor: pointer;
  width: 70px;
  right: 10px;
  top: 15px;
  text-align: left;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.activetabjobs {
  background-color: #42ab5b;
  color: #fff !important;
  padding: 5.4px;
  border-radius: 10px;
  margin-bottom: 2px;
}

.dashboardCard {
  height: 100%;
  padding: 0 !important;
}

.dashboardCard {
  height: 100%;
  padding: 0 !important;
}

.custom-date {
  color: white !important;
  font-size: 22px !important;
  font-weight: 500;
}

@media (max-width: 768px) {

  .custom-date {
    color: white !important;
    font-size: 20px !important;
    font-weight: 500;
  }
}



#custom-calendar .dateArrowHolder {
  display: block !important;
}

.mainNavJob::-webkit-scrollbar {
  display: none !important;
}






.talentsbottomrightbox {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 90%;
  background: linear-gradient(to right, transparent 0%, black 100%);
}

.talentsavtar {
  position: relative;
  text-align: center;
  color: white;
}

.talentsbottomright {
  text-align: right;
  padding-right: 10px;
}

.BtnFollowing {
  color: #005e32 !important;
  cursor: pointer;
}

.BtnFollower {
  background: #005e32 !important;
  box-shadow: none !important;
}

.BtnFollowingtinsti {
  color: #a91774 !important;
  cursor: pointer;
}

.BtnFollowerinsti {
  background: #a91774 !important;
  box-shadow: none !important;
}

.BtnFollowingcomp {
  color: #001892 !important;
  cursor: pointer;
}

.BtnFollowercomp {
  background: #001892 !important;
  box-shadow: none !important;
}

.rightpannelcard {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  /* padding: 10px 100px 10px 110px; */
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
  /* padding: 3rem 1rem; */
  padding: 1rem 1rem 2rem 1rem;
}

.rightpannelcardOne {
  position: relative;
  border: 1px solid #cce0ff;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.replyBox {
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 10px;
}

.activetabjobs {
  /* background-color: #42ab5b; */
  background-color: #0b4029;
  color: #fff !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 2px;
}

@media (min-width: 2000px) {
  .jobLikedSliderCon .slick-arrow.slick-prev {
    left: -10% !important;
  }

  .jobLikedSliderCon .slick-arrow.slick-next {
    right: -10% !important;
  }
}





@media (min-width: 1900px) {
  .qr {
    width: 5.5rem !important;
    height: 7rem !important;
  }
}


#overlay footer a img.logoImage {
  width: 30%;
}

@media (max-width: 768px) {
  #overlay footer a img.logoImage {
    width: 60%;
  }
}

.Fcontainer {
  max-width: 2600px;
}

.urlInput {
  width: 270px;
}

@media (max-width: 768px) {
  .urlInput {
    width: 100%;
  }
}

.loginH1 {
  font-size: 1.3rem !important;
}

@media only screen and (max-width: 768px) {
  .loginH1 {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {

  /* @media (min-width: 601) and (max-width: 992px) { */
  .loginH1 {
    font-size: 1.2rem !important;
  }
}

#LoginPageNew .slick-prev,
#RegPageNew .slick-prev {
  left: 1rem !important;
}

#LoginPageNew .slick-next,
#RegPageNew .slick-next {
  right: 1rem !important;
}

@media only screen and (max-width: 768px) {

  #LoginPageNew .slick-next,
  #RegPageNew .slick-next {
    right: 0.5rem !important;
  }

  #LoginPageNew .slick-prev,
  #RegPageNew .slick-prev {
    left: 0.5rem !important;
  }
}

/* -------------------- */

#overlay footer #footerMenus ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#overlay footer #footerMenus ul li a {
  text-decoration: none;
}

#overlay footer #footerMenus ul li a,
#overlay footer #footerMenus ul li {
  color: #00ab55;
}

#overlay footer #footerMenus ul li a:hover,
#overlay footer #footerMenus ul li:hover {
  color: #ffc300;
}

#overlay footer #footerMenus ul li {
  margin: 0.15rem;
  padding: 0.15rem;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  #footerMenus ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* #footerMenus li {
    flex: 1 0 49%;
    margin-bottom: 5px;
  } */
  #overlay footer #footerMenus ul li {
    margin: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1220px) {
  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenus ul {
    /* flex-wrap: wrap-reverse; */
    flex-wrap: wrap;
  }

  #overlay footer #footerMenus ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }
}

#overlay footer a img.logoImage {
  width: 25%;
}

@media (max-width: 768px) {
  #overlay footer a img.logoImage {
    width: 60%;
  }
}

@media (min-width: 1800px) {
  #overlay footer a img.logoImage {
    width: 22%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #overlay footer a img.logoImage {
    width: 50%;
  }
}

@media (min-width: 1900px) {
  .qr {
    width: 3.5rem !important;
    height: 4rem !important;
  }
}

.shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

#articleContainer {
  column-count: 3;
  column-gap: 3%;
  column-width: 50%;
  min-height: 20vh;
}

#articleContainer p {
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  #articleContainer {
    column-count: 1;
    column-width: 100%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #articleContainer {
    column-count: 2;
    column-width: 47%;
    column-gap: 3%;
  }
}

.album .centeredText {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
  margin: auto;
  height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 1;
}

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 14.8rem;
}

.UploadLoading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 19rem;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 10px;
  margin: 10px;
  width: 10px;
  border-radius: 50%;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }

  50% {
    background-color: #2f3812;
    transform: scale(1.3);
  }

  100% {
    background-color: grey;
    transform: scale(1);
  }
}



.qr2 {
  width: 100% !important;
  height: 4rem !important;
}

@media (min-width: 992px) {
  .qr2 {
    width: 100% !important;
    height: 6rem !important;
  }
}

.accordianCeruleanTitle {
  background: #1bc9e4;
  width: max-content;
}

.accordianRedTitle {
  background: #ff754a;
  width: max-content;
}

.accordianOranceTitle {
  background: #ffc455;
  width: max-content;
}

.accordianVioletTitle {
  background: #16a851;
  width: max-content;
}

.accordianVioletTitle .titleIcon {
  /* background: url("../src/images/portfolio-icon.png") no-repeat center center
    #16a851; */

  background: url("../src/images/Portfolio.png") no-repeat center center #0f9143;
  background-size: contain;
}

.accordianYellowTitle {
  background: #ffc455;
  width: max-content;
}

.accordianBlueTitle {
  /* background: #6b96e6; */
  background: #0f347a;
  width: max-content;
}

.indicator button {
  width: 25%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #d3ead3;
}

.indicatorBlue button {
  width: 25%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  background: #d3e1ea;
}

#articleContainer {
  column-count: 2;
  column-gap: 3%;
  column-width: 50%;
  min-height: 20vh;
}

@media only screen and (max-width: 768px) {
  #articleContainer {
    column-count: 1;
    column-width: 100%;
  }
}

.loginH1 {
  font-size: 1.1rem !important;
}

.accordianVioletTitle .titleIcon {
  background: url("../src/images/Portfolio3.png") no-repeat center center #0f9143;
}

#themeScreen>div:first-child .image-container-main input+div {
  display: none;
}

#themeScreen>div:first-child .image-container-main input+div+div span {
  padding: 0;
  border-radius: 0;
}

.tBannerText007 {
  position: absolute;
  bottom: 3%;
  left: 4%;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .tBannerText007 {
    bottom: 25%;
  }
}

.tBannerText007p {
  position: absolute;
  bottom: 3%;
  left: 4%;
  z-index: 2;
}

.Menupopover>div.MuiPaper-root>span:last-of-type,
.Menupopover>div.MuiPaper-root>div:last-of-type {
  /* Your styles here */
  display: none;
}

.AgentCard {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.qr3 {
  width: 100% !important;
  height: 4rem !important;
}

.accordianBlueSTitle {
  background: #1bc9e4;
  width: 100%;
  /* width: max-content; */
}

.accordianBlueSTitle .titleIconInner {
  background: url("../src/images/onscreen-performance.jpg") no-repeat center center #07a4cd;
}

.tBannerText007Screenshot {
  position: absolute;
  bottom: 3%;
  left: 4%;
  z-index: 2;
}

.agentIconCon {
  border: 1px solid #a7debf;
  border-radius: 5px;
  padding: 10px;
  min-height: 3.5rem;
  min-width: 3.5rem;
}

.agentIconConMobile {
  border: 1px solid #a7debf;
  border-radius: 5px;
  padding: 10px;
  min-height: 7rem;
  min-width: 3.5rem;
}

@media only screen and (max-width: 768px) {
  .incompleteprofessionCardHeader {
    background: rgba(220, 53, 70, 0.692) !important;
  }
}

.profileImgLocationTwo {
  max-width: 100%;
  height: 85vh;
  width: 100%;
}

.calenderBox {
  width: 40px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  color: White;
}



@media (max-width: 768px) {

  .custom-date {
    color: white !important;
    font-size: 20px !important;
    font-weight: 500;
  }
}

















.projectImgHolder .thumbHolder {
  position: relative;
  height: 350px;
  width: auto !important;

  /* width:100%; */
}

.urlInput {
  width: 100%;
}

.shadow-sm2 {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24) !important;
}

.subscribePlanDash .activePlanProgText {
  position: absolute;
  top: 25%;
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #RegPageNew #RegPageNewBox {
    height: 170vh;
  }

  #RegPageNew .slick-list {
    height: 170vh;
  }

  #RegPageNew .slide {
    height: 170vh;
  }

  #RegPageNew #overlay {
    min-height: 170vh !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .profileImgLocationTwo {
    max-width: 100%;
    height: 30vh !important;
    width: 100%;
    object-position: top;
  }
}

/* -------------------170823------------------------- */

.AuthFooter {
  width: 52%;
}

.AuthRegisterFooter {
  width: 42%;
}

@media (min-width: 1536px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 100vh;
  }

  #RegPageNew .slide {
    height: 100vh;
  }

  #RegPageNew #overlay {
    min-height: 100vh !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  /* #LoginPageNew #LoginPageNewBox {
    height: 100vh;
  }

  #LoginPageNew .slick-list {
    height: 100vh;
  }

  #LoginPageNew .slide {
    height: 100vh;
  } */

  #RegPageNew #RegPageNewBox {
    height: 110vh;
  }

  #RegPageNew .slick-list {
    height: 110vh;
  }

  #RegPageNew .slide {
    height: 110vh;
  }

  #RegPageNew #overlay {
    min-height: 100vh !important;
  }
}

.loginInputHolderRegister .css-uji1fu-MuiFormHelperText-root {
  margin-top: 0px !important;
}

.loginInputHolderRegister .css-12sft6w-MuiInputBase-root-MuiOutlinedInput-root {
  height: 3rem !important;
}

.loginInputHolderRegister .css-1mzin3n-MuiInputBase-root-MuiOutlinedInput-root {
  height: 3rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
    /* height: auto; */
  }

  #RegPageNew .slick-list {
    height: 130vh;
    /* height: auto; */
  }

  #RegPageNew .slide {
    height: 130vh;
    /* height: auto; */
  }

  #RegPageNew #overlay {
    min-height: 130vh !important;
    /* min-height: auto !important; */
  }

  .AuthFooter {
    width: 55%;
  }

  .AuthRegisterFooter {
    width: 55%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 768px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 160vh;
  }

  #RegPageNew .slide {
    height: 160vh;
  }

  #RegPageNew #overlay {
    min-height: 160vh !important;
  }

  .AuthFooter {
    width: 80%;
  }

  .AuthRegisterFooter {
    width: 100%;
  }
}

@media (max-width: 385px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 220vh;
  }

  #RegPageNew .slide {
    height: 220vh;
  }

  #RegPageNew #overlay {
    min-height: 220vh !important;
  }

  .AuthFooter {
    width: 100%;
  }

  .AuthRegisterFooter {
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1536px) {
  #LoginPageNew #LoginPageNewBox {
    height: 100vh;
  }

  #LoginPageNew .slick-list {
    height: 100vh;
  }

  #LoginPageNew .slide {
    height: 100vh;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #overlay header a img.logoImage {
    width: 28%;
  }

  #overlay footer a img.logoImage {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 170vh;
  }

  #RegPageNew .slide {
    height: 170vh;
  }

  #RegPageNew #overlay {
    min-height: 170vh !important;
  }
}

@media (max-width: 768px) {
  .professionPopover span {
    display: none;
  }
}



.accordianLocation1 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.profileImgLocation {
  border-radius: 8px;
}

.qr2 {
  width: fit-content !important;
  height: 6rem !important;
  outline: 8px solid rgba(141, 138, 138, 0.133);
  border-radius: 0.5px;
  margin-right: 7px;
}

#articleContainer {
  min-height: 3rem;
}

.accordianCeruleanTitle {
  background: #1bc9e4;
  width: 100%;
}

.accordianRedTitle {
  background: #ff754a;
  width: 100%;
}

.accordianYellowTitle {
  background: #ffc455;
  width: 100%;
}

.accordianBlueTitle {
  background: #6b96e6;
  width: 100%;
}

.accordianOranceTitle {
  background: #ffc455;
  width: 100%;
}

.accordianVioletTitle {
  background: #16a851;
  width: 100%;
}

/* .indicatorProfessionTheme .css-1v0hpbg-MuiTabs-indicator {
  background: transparent !important;
} */

.indicator .MuiTabs-indicator {
  background-color: transparent !important;
}

.indicatorProfessionTheme button.Mui-selected {
  /* background: #ffffff !important; */
  background-color: "transparent" !important;
  color: #07a5ce !important;
  font-size: 19px;
}

@media (max-width: 992px) {
  .indicatorProfessionTheme button.Mui-selected {
    font-size: 16px;
  }
}

.indicatorBlue .MuiTabs-indicator {
  background-color: transparent;
}

.indicatorBlue .css-heg063-MuiTabs-flexContainer {
  background-color: #d3e1ea !important;
}

.indicatorBlue button {
  border-right: 1px solid #d7cfcf;
}

.indicator .css-heg063-MuiTabs-flexContainer {
  background-color: #d3ead3;
}

.themePageImage {
  border-radius: 8px;
}

#themeScreen img {
  border-radius: 8px;
}

.indicatorBlue {
  background-color: #d3e1ea !important;
}

.indicator {
  background-color: #d3ead3 !important;
}

.locationAccordianTitleHolder {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.locationAccordianTitleHolder .MuiAccordionSummary-content {
  margin: 0 !important;
  justify-content: center;
}

.educationicon .MuiAccordionSummary-expandIconWrapper {
  background: #1bc9e4 !important;
  height: 50px !important;
}

.languageicon .MuiAccordionSummary-expandIconWrapper {
  background: #3A66E6 !important;
  height: 50px !important;
}

.professionicon .MuiAccordionSummary-expandIconWrapper {
  background: #ff754a !important;
  height: 50px !important;
}

.socialicon .MuiAccordionSummary-expandIconWrapper {
  background: #ffc455 !important;
  height: 50px !important;
}

.porfolioicon .MuiAccordionSummary-expandIconWrapper {
  background: #16a851 !important;
  height: 50px !important;
}

/* .locationAccordianTitleHolder .MuiAccordionSummary-expandIconWrapper {
  background: #ffc455;
  height: 50px;
} */

.locationTable table {
  border: none;
  /* border-radius: 15px; */
  /* margin-bottom: 20px;  */
}

.locationTableTheme table {
  border: none;
  border-radius: 0px;
  margin-bottom: 0px;
}

.TableRow1 {
  border-bottom: 1px solid #d3e1ea;
}

.TableRow2 {
  border: none;
}

.indicatorProfessionTheme .MuiTabs-indicator {
  background-color: transparent;
}

.indicatorBlue button.Mui-selected {
  background: #0f347a;
  border-color: #0f347a;
  color: #fff;
  width: 25%;
}

.indicatorProfessionTheme .MuiTabs-indicator {
  background-color: transparent !important;
}

.taableRow {
  border-bottom: none !important;
}

.indicatorBlue {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

table.skillsetTablenew>tr:nth-of-type(odd)>* {
  background-color: #f4f9fb;
}

.userDoneForm {
  display: flex;
  row-gap: 10px;
  flex-wrap: wrap;
}

.accordianLocation {
  border: 1px solid #d3e1ea !important;
  border-radius: 0 !important;
  width: calc(100% - 65px);
  /* margin-left: 65px !important; */
  margin-left: 35px !important;
}

/* @media only screen and (min-width: 400px) and (max-width: 768px) {
  #RegPageNew #RegPageNewBox {
    height: 100vh;
  }

  #RegPageNew .slick-list {
    height: 200vh;
  }

  #RegPageNew .slide {
    height: 200vh;
  }

  #RegPageNew #overlay {
    min-height: 200vh !important;
  }

  .AuthFooter {
    width: 80%;
  }

  .AuthRegisterFooter {
    width: 100%;
  }
} */

@media (max-width: 768px) {
  .accordianLocation {
    margin-left: 0 !important;
  }

  .indicatorBlue button.Mui-selected {
    width: fit-content;
  }

  .indicatorBlue button {
    width: fit-content;
  }

  .ProfileCropper {
    height: 450px !important;
  }
}

@media (min-width: 1200px) {
  .indicatorBlue button.Mui-selected {
    width: 20%;
  }

  .indicatorBlue button {
    width: 20%;
  }
}

#overlay footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

/* #LoginPageNewBox {
  padding-bottom: 7.2rem;
} */

@media only screen and (max-width: 768px) {
  #LoginPageNewBox {
    padding-bottom: 7.2rem !important;
  }

  #LoginPageNew #LoginPageNewBox {
    height: calc(120vh + 7.2rem);
  }

  #LoginPageNew .slick-list {
    /* height: 120vh; */
    height: calc(120vh + 7.2rem);
  }

  #LoginPageNew .slide {
    height: calc(120vh + 7.2rem);
  }
}

.MyProjectViewTab>div {
  padding: 0px !important;
}

.myprojectmessage {
  border-radius: 8px !important;
  border: 1px solid #ccc;
}

.myprojectmessage::before {
  border: none !important;
}

.myprojectmessage::after {
  border: none !important;
}

.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Z_index {
  z-index: 2;
}

@media (max-width: 768px) {
  #footerMenus ul {
    justify-content: start !important;
  }
}

@media only screen and (max-width: 768px) {
  #LoginPageNewBox {
    padding-bottom: 0rem !important;
  }

  #LoginPageNew #LoginPageNewBox {
    height: 100%;
  }

  #LoginPageNew .slick-list {
    /* height: 120vh; */
    height: 100%;
  }

  #LoginPageNew .slide {
    height: 100%;
  }

  .resetConfPass #overlay footer img {
    width: 45px !important;
  }
}

.AuthFooter {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #RegPageNew #RegPageNewBox {
    /* height: 100vh; */
    height: 100%;
  }

  #RegPageNew .slick-list {
    height: 200vh;
    height: 100%;
  }

  #RegPageNew .slide {
    height: 100%;
  }

  #RegPageNew #overlay {
    min-height: min-content !important;
    padding-bottom: 8rem;
    background-image: url(./images/slider3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.loginMessage {
  height: auto !important;
}

.checkbox {
  margin-right: 0px !important;
}

.jobListTable th {
  padding: 12px 12px;
  text-align: start;
}

.jobListTable th,td {
  padding: 12px 12px;
  text-align: start;
}

.jobModelButton {
  display: flex;
  justify-content: flex-end;
  margin: 2rem;
  margin-right: 4rem;
}

.SideScrollbar::-webkit-scrollbar {
  width: 3px;
}

.SideScrollbar::-webkit-scrollbar-thumb {
  background-color: #0b4029;
  height: 10px;
}

.SideScrollbar::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.OfficeJobBox {
  max-height: 7rem;
  overflow: auto;
}

.messageAreaBtn {
  position: absolute;
  top: 22px;
  right: 20px;
}

.messageArea::-webkit-scrollbar {
  display: block;
}



/* ----------------091023-------------------------- */

@media (max-width: 768px) {

  /* .css-1t3bhbv {
    border-radius: 8px !important;
  }

  .css-tno0iu-MuiPaper-root-MuiCard-root {
    border-radius: 8px !important;
  } */
  .cardRadius {
    border-radius: 8px !important;
  }
}

.dashViewList {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fff;
  border-bottom-left-radius: 5px !important;
}

.dashViewIcon {
  margin: 1px;
  vertical-align: bottom;
  width: 25px !important;
  height: 25px !important;
}

.dashViewIconMobile {
  margin: 1px;
  vertical-align: bottom;
  width: 17px !important;
  height: 16px !important;
}

.dashViewSlide .slick-slide {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.dashViewSlide .slick-list {
  /* min-height: 5rem !important; */
  height: 5rem !important;
}

.dashViewSlide .slick-prev,
.slick-next {
  /* min-height: 5rem !important; */
  width: 30px !important;
  position: absolute;
  top: 40%;
  margin-left: 5px;
  margin-right: 5px;
}

.EduandTriTabs .MuiTabs-root {
  color: #0b4029 !important;
  border-radius: 10px;
  border: 1px solid #42ab5b !important;
  min-height: 40px !important;
}

.EduandTriTabs .MuiTabs-indicator {
  background-color: transparent !important;
}

.EduandTriTabs .MuiTabPanel-root {
  min-height: 25rem !important;
  margin-top: 1rem;
}

.EduandTriTabs .MuiTabs-flexContainer {
  justify-content: space-between !important;
  padding: 0px 8px !important;
}

.EduandTriTabs .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 500;
  font-size: 15px;
  color: #0b4029;
  min-height: 38px !important;
  padding-left: 13px;
  padding-right: 13px;
}

.EduandTriTabs .Mui-selected {
  color: #fff !important;
  background-color: #0b4029;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 8px !important;
  margin: 6px 0px !important;
}

.EduEmptyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.eduTriTable {
  box-shadow: none;
  border: 2px solid #a9ccbf;
  /* margin: 10px; */
}

.eduTriTableSlider .slick-dots {
  bottom: 0px;
}

.eduTriTableSlider {
  padding-bottom: 1rem;
}

.eduTriTableSlider .slick-dots li button:before {
  font-family: "slick";
  font-size: 17px;
}

.eduTriTableSlider .slick-dots li.slick-active button:before {
  opacity: 0.85;
  color: #006742;
}

.startIcon .MuiButton-startIcon {
  margin-right: 4px !important;
}

.eduTriTableSlider .slick-slide {
  padding: 5px !important;
}

.eduTriTableSlider .slick-next {
  display: none !important;
}

.eduTriTableSlider .slick-prev {
  display: none !important;
}

.ProfessionDataSlider .slick-dots {
  bottom: -10px;
}

.ProfessionDataSlider .slick-dots li button:before {
  font-family: "slick";
  font-size: 15px;
}

.starticonss {
  margin-right: 15px !important;
}

.ProfDataTabs {
  color: #0b4029 !important;
  border-radius: 10px;
  border: 1px solid #42ab5b !important;
  min-height: 40px !important;
}

.ProfDataTabs .MuiTabs-indicator {
  background-color: transparent;
}

.ProfDataTabs .MuiTabPanel-root {
  min-height: 25rem !important;
}

.ProfDataTabs .MuiTabs-flexContainer {
  justify-content: space-between !important;
}

.ProfDataTabs .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 500;
  font-size: 15px;
  color: #44577a;
  min-height: 38px !important;
  min-width: 30px !important;
}

.ProfDataTabs .MuiTabs-flexContainer {
  gap: 10px !important;
  padding: 0px 8px !important;
}

.ProfDataTabs .MuiButtonBase-root:first-child {
  padding-left: 10px !important;
}

.ProfDataTabs .MuiButtonBase-root:last-child {
  padding-right: 10px !important;
}

.ProfDataTabs .MuiTabScrollButton-root {
  width: 30px !important;
  border-radius: 0px !important;
  background-color: rgba(66, 171, 91, 0.2) !important;
}

.ProfDataTabs .Mui-selected {
  color: #fff !important;
  background-color: #0b4029;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 8px !important;
  margin: 6px 0px !important;
}

.workCreditCard {
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

/* .agentIconConMobileSlide {
  border: 1px solid #a7debf;
  border-radius: 50%;
  padding: 20px;
  min-height: 3.5rem;
  min-width: 3.5rem;
} */

.progressTextMobile {
  display: inline-block !important;
  color: #fff;
  width: 16%;
  margin-right: 2px;
}

.industryTextField input {
  height: 15px !important;
}

.infoIcon {
  display: flex;
  align-items: center;
  gap: 4px;
}

.physicalSliderMobile .MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabelOpen {
  top: -9px !important;
  right: -15px !important;
  background-color: #006743 !important;
}

.physicalSliderMobile .MuiSlider-thumb[data-index="2"] {
  height: 18px !important;
  width: 18px !important;
}

.physicalSliderMobile .MuiSlider-thumb[data-index="1"] {
  background: #fff !important;
  width: 7px !important;
  height: 7px !important;
}

.physicalSliderMobile .MuiSlider-thumb[data-index="0"] {
  height: 18px !important;
  width: 18px !important;
}

.physicalSliderMobile .MuiSlider-valueLabelOpen {
  background-color: #006743 !important;
  border-radius: 3px !important;
  top: 19px !important;
}

.physicalSliderMobile .MuiSlider-valueLabelOpen::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: inherit;
  right: -24%;
  top: 8%;
}

.physicalSlider .MuiSlider-valueLabelOpen {
  background-color: #006743 !important;
}

.WorkCreditEmpty {
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.awardSlider .slick-dots {
  bottom: -20px !important;
}

.Profile001Slide .slick-dots li button:before {
  font-family: "slick";
  font-size: 10px !important;
}

.Profile001Slide .slick-dots li {
  width: 10px !important;
}

/* .profile001Type {
  text-align: end;
  background: linear-gradient(to left, #081606, transparent);
  color: white;
  padding: 10px;
  font-size: 19px;
  font-weight: 500;
  position: absolute;
  bottom: 7px;
  right: 6px;
  z-index: 1;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 100%;
} */

/* .profile001Type {
  text-align: end;
  background: linear-gradient(to left, #081606, transparent);
  color: white;
  padding: 10px;
  font-size: 19px;
  font-weight: 500;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  position: absolute;
  top: 25.9rem;
  width: 106%;
  transform: translate(0%, -50%);
} */

.profile001TypeFor1 {
  text-align: end;
  background: linear-gradient(to left, #081606, transparent);
  color: white;
  padding: 10px;
  font-size: 19px;
  font-weight: 500;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  /* max-width: 20rem; */
  /* position: absolute; */
  /* top: 94%; */
  /* top: 25.9rem; */
  width: 100%;
  transform: translate(0%, -100%);
}

.profile001Type {
  text-align: end;
  background: linear-gradient(to left, #081606, transparent);
  color: white;
  padding: 10px;
  font-size: 19px;
  font-weight: 500;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  /* max-width: 20rem; */
  /* position: absolute; */
  /* top: 94%; */
  /* top: 25.9rem; */
  width: 100%;
  margin-left: 34px;
  transform: translate(0%, -100%);
}

.profile001Img {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.Profile001Slide .slick-slide+div {
  position: relative !important;
}

.profile001Btn {
  color: #83ada1 !important;
  padding: 8px !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.profile001Btn .MuiButton-endIcon {
  margin-left: 3px !important;
}

@media (max-width: 400px) {
  .profile001Btn {
    padding: 4px !important;
    font-size: 10px !important;
  }
}

/* @media (max-width: 360px) {
  .profile001Btn {
    padding: 4px !important;
    font-size: 10px !important;
  }
} */

.ProfileProfessionTab .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 700;
  /* font-size: 18px; */
  font-size: 16px;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  border-right: 1px solid gray;
  padding: 0px 10px;
}

.ProfileProfessionTab .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfileProfessionTab .MuiTabs-indicator {
  background-color: #dc1045 !important;
  width: 40px !important;
  margin-left: 10px !important;
}

.ProfileProfessionTab .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.ProfileDetailsTab .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 600;
  font-size: 14px !important;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  border-right: none !important;
  padding: 0px 10px !important;
  min-width: 50px !important;
}

@media (max-width: 390px) {
  .ProfileDetailsTab .MuiButtonBase-root {
    font-size: 14px !important;
    padding: 0px 6px !important;
  }
}

.ProfileDetailsTab .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfileDetailsTab .MuiTabs-indicator {
  background-color: #dc1045 !important;
  width: 40px !important;
  margin-left: 6px !important;
}

.ProfileDetailsTab .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.normalText {
  font-size: 16px !important;
  color: #686868;
}

.newEduTabInd .MuiTabs-indicator {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  .profileBtn .MuiBadge-root {
    color: #0b4029;
    margin-right: 20px;
  }

  .profileBtn .MuiBadge-root svg {
    width: 30px;
    height: 30px;
  }

  .profileBtn .MuiBadge-badge {
    background-color: #0b4029;
    color: #fff;
    top: 5px;
    right: 3px;
    /* top: 22px;
  right: 0; */
  }

  /* 
  .profileBtnMobile .MuiBadge-root {
    color: #0b4029;
    margin-right: 20px;
  }

  .profileBtnMobile .MuiBadge-root svg {
    width: 30px;
    height: 30px;
  }

  .profileBtnMobile .MuiBadge-badge {
    background-color: #0b4029;
    color: #fff;
    top: 5px;
    right: 3px;
  }
  */
}

/* .tableCustom1 th,
.tableCustom1 td  */
.mobileSkillsTable th {
  border-bottom: 1px dashed #ccc !important;
  vertical-align: baseline;
  padding-top: 10px;
  color: #475a7c;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.mobileSkillsTable td {
  border-bottom: 1px dashed #ccc !important;
  padding: 10px !important;
  padding-right: 0px !important;
  font-size: 14px;
  color: #000 !important;
  text-align: left;
}

/* 
.ProfileDetailsTab .MuiButtonBase-root {
  font-size: 14px !important;
  padding: 0px 13px !important;
} */
.ProfilePortfolioTab .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 600;
  font-size: 14px !important;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  border-right: none !important;
  /* padding: 0px 13px !important; */
  min-width: 50px !important;
  padding-right: 13px !important;
}

.ProfilePortfolioTab .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfilePortfolioTab .MuiTabs-indicator {
  background-color: #dc1045 !important;
  width: 30px !important;
  /* margin-left: 12px !important; */
}

.ProfilePortfolioTab .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.videolistMobile {
  width: 100% !important;
}

/* @media (max-width: 385px) { */
.dashboardEyeView {
  font-size: 9px !important;
  margin-top: 13px !important;
  /* } */
}

.workCreditTableMobile th,
.workCreditTableMobile td {
  width: 100%;
  /* Set full width for all columns on smaller screens */
  border: none !important;
  background-color: transparent !important;
}

.workCreditTableMobile thead th {
  color: #475a7c;
  font-size: 15px;
  font-weight: 600 !important;
  text-align: left;
  padding: 12px;
}

.workCreditTableMobile tbody td {
  color: #000 !important;
  font-size: 14px;
  color: #000 !important;
  text-align: left;
  /* border-bottom: 1px dashed #ccc !important; */
  /* word-break: break-all; */
}

.workCreditTableMobile tbody tr {
  border-bottom: 1px dashed #ccc !important;
}

.workCreditTableMobile tbody tr:last-child {
  border-bottom: none !important;
}

.ShiftCvHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 0px !important;
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
  z-index: 2;
}

.CvFollowLogo {
  height: 25px;
  width: 20px;
  margin-right: 5px;
  margin-left: 3px;
}

.mobileSkillsetBox {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 8px;
  justify-content: start;
  overflow-y: auto;
  /* height: 150px;
  padding: 16px; */
}

@media (max-width: 768px) {
  .loginSwiper .slick-slide {
    display: none !important;
  }

  /* .mobileBackground {
    background-image: url(./images/slider3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  } */
  #footerMenus {
    margin: 8px !important;
    margin-bottom: 1rem !important;
  }

  #LoginPageNew #overlay {
    /* min-height: min-content !important; */
    /* padding-bottom: 7rem; */
    background-image: url(./images/slider3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.langPlaceholder label {
  font-size: 13px !important;
}


@media only screen and (max-width: 768px) {
  .jobLikedSliderCon .slick-arrow.slick-next {
    right: -55px !important;
    top: 42px;
  }
}

@media only screen and (max-width: 768px) {
  .jobLikedSliderCon .slick-arrow.slick-prev {
    left: -48px !important;
    top: 40px;
  }

  .albModal {
    padding: 16px !important;
    width: 100% !important;
    height: auto !important;
  }
}

/* @media (max-width: 768px) {
  .EducationDatePicker .MuiPopper-root {
    inset: auto auto 0px 20px !important;
  }
  .EducationDatePicker .MuiPaper-root {
    margin-left: 20px !important;
  }
} */

/* .css-17pmaar-MuiCardContent-root {
  padding: 16px !important;
} */
.generalProgress {
  width: 80px !important;
}

.PortfolioUploadImg {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {

  /* .css-1mtsuo7 .MuiPopper-root {
    inset: auto auto 0px 20px !important;
  } */
  .css-1mtsuo7 .MuiPaper-root {
    margin-left: 20px !important;
  }

  .MuiPopper-root .MuiPaper-root {
    margin-left: 20px !important;
  }

  /* .css-j5h6pi-MuiPopper-root {
    inset: auto auto 0px 20px !important;
  } */
  .css-j5h6pi-MuiPopper-root .MuiPaper-root {
    margin-left: 20px !important;
  }

  .MuiPickersPopper-root .MuiPaper-root {
    margin-left: 20px !important;
  }

  /* .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    inset: auto auto 0px 20px !important;
  } */
  /* .MuiPopper-root {
    inset: auto auto 0px 20px !important;
  } */
}

.greenText {
  color: #006742 !important;
}

.WorkCreditMobileSilde {
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  margin-top: 8px;
}

.CPC {
  text-transform: capitalize !important;
}

.portfolioPhotoEditMobile {
  object-fit: inherit !important;
  background: #fff;
}

.displayCenter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.SingleAlbumView {
  justify-content: center;
  height: 20rem;
  align-items: center;
}

/* .workcreditforms {
  height: 100% !important;
} */
.workcreditforms form {
  height: calc(100% - 64px);
}

.ImgUploadModalBtn {
  position: fixed;
  bottom: 30px;
  padding: 10px;
  width: 91%;
  text-align: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #fff;
}

.SnackbarContent-root {
  flex-wrap: nowrap !important;
}

.userEducationTrainingMob li {
  min-height: 0px !important;
  padding-top: 4px !important;
  padding-bottom: 5px !important;
}

.userEducationTrainingMob span {
  display: none !important;
}

.userEducationTrainingMob .MuiPaper-root {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  /* box-shadow: -15 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24) */
}

.ProfessionDataMenu .MuiPaper-root {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 0px 0px rgba(0, 0, 0, 0.23) !important;
  /* box-shadow: -15 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24) */
}

.messageSuccessManage {
  font-size: 11px;
  position: absolute;
  bottom: 15px;
  /* bottom: 3px; */
  color: #00ab55;
  margin-left: 10px;
  font-weight: normal;
}

.imageUploadBtnProfilepagemobile {
  background: #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.imageUploaderProfilePageBoxMobile {
  position: absolute;
  bottom: 4%;
  right: 6%;
}

@media (max-width: 330px) {
  .imageUploaderProfilePageBoxMobile {
    right: 0%;
  }
}

@media (min-width: 375px) and (max-width: 400px) {
  .imageUploaderProfilePageBoxMobile {
    right: 9%;
  }
}

@media (min-width: 400px) and (max-width: 425px) {
  .imageUploaderProfilePageBoxMobile {
    right: 13%;
  }
}

@media (min-width: 426px) and (max-width: 460px) {
  .imageUploaderProfilePageBoxMobile {
    right: 16%;
  }
}

@media (min-width: 461px) and (max-width: 490px) {
  .imageUploaderProfilePageBoxMobile {
    right: 18%;
  }
}

@media (min-width: 491px) and (max-width: 530px) {
  .imageUploaderProfilePageBoxMobile {
    right: 21%;
  }
}

@media (min-width: 531px) and (max-width: 768px) {
  .imageUploaderProfilePageBoxMobile {
    right: 25%;
    /* top:'' */
  }
}

.UpadateSuccessDialog {
  background-color: #078457;
  text-align: center;
  padding: 20px 16px;
}

.UpdateSuccessCloseIcon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.UpdateSuccessTickIcon {
  background: #fff;
  border-radius: 50%;
  padding: 7px;
}

.UpdateSuccessBtn {
  font-weight: 500 !important;
  background: #0b5029 !important;
}

@media (max-width: 389px) {
  .UpdateSuccessBtn {
    font-size: 12px !important;
  }
}

.Profile001SlideImg {
  height: 27rem;
  width: 100%;
  /* max-width: 20rem; */
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}


.ProfileCropper .cropper-face {
  min-height: 230px !important;
}

.ProfileImgMobile img {
  object-fit: unset !important;
}

.NextBackFont {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.NextBackFont .MuiButton-endIcon {
  margin-left: 2px !important;
}

.NextBackFont .MuiButton-startIcon {
  margin-right: 2px !important;
}

.floatTab {
  margin: 5px 0px !important;
}

.floatTabBox {
  align-items: center !important;
  padding: 0px 6px !important;
}

/* -------------------------------------------------------------------------------- */

.SliderLeft {
  /* margin-left: 44px !important; */
  margin-left: 34px !important;
}

.termCondCheckboxMobile .MuiFormControlLabel-root {
  margin-right: 5px !important;
}

.termCondCheckbox .MuiFormControlLabel-root {
  margin-right: 5px !important;
}

.professionArrow .MuiSvgIcon-root {
  margin-top: 10px;
  color: #fff;
  width: 30px;
  height: 30px;
}

.anchorTagJobSearch {
  text-decoration: none;
  color: #000 !important;
}

.firstLetter::first-letter {
  text-transform: uppercase !important;
}

.transform {
  text-transform: capitalize !important;
}

.corporateFareContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Radius5 {
  border-radius: 5px !important;
}

.profileLink {
  color: #44577a;
  text-decoration: none;
}

.image_container {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  /* 5:4 aspect ratio (4 / 5 * 100) */
  overflow: hidden;
  border-radius: 5px;
}

.fixed_ratio_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
}

.tab-space {
  margin-right: 8px;
  /* Adjust the value as needed for your desired spacing */
}

.socialIcons img {
  width: 35px;
  height: 35px;
}

.socialIcons .MuiIconButton-root {
  padding-left: 0px !important;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.socialIcons.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.profileFollowbtn .MuiButtonBase-root {
  border-radius: 3px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.ScreenShotprofileFollowbtn .MuiButtonBase-root {
  border-radius: 3px !important;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
  padding-right: 35px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-right: 8px !important;
}

.profileFollowbtn {
  display: flex;
  gap: 8px;
}

.profileFollowLogo {
  height: 21px;
  width: 20px;
}

.ScreenShotprofileFollowLogo {
  height: 21px;
  width: 20px;
  position: absolute;
}

.theme2Background {
  background-color: #f3f7ff;
  border-radius: 4px !important;
  border: 1px solid #ccc;
}

/* .css-1unbkm8-MuiTableCell-root:first-of-type {
  box-shadow: inset 8px 0 0 #fff;
} */
.educationTable2 .MuiTableCell-root {
  box-shadow: none !important;
}

.educationTable2Body tr:last-child {
  border-bottom: none !important;
}

.onSkillsetTable2 {
  table-layout: fixed;
}

.onSkillsetTable2 th {
  width: 300px;
  background-color: transparent !important;
}

.onSkillsetTable2 tr {
  border-bottom: 1px solid #ccc !important;
}

.onSkillsetTable2 td {
  color: #000 !important;
  font-weight: 600;
}

.onSkillsetTable2 th {
  color: #44577a !important;
  font-weight: 600;
}

.onSkillsetTable2 tr:last-child {
  border-bottom: none !important;
}

.onSkillsetTable2 .MuiTableCell-root {
  box-shadow: none !important;
}

.onSkillsetTable2Lang {
  table-layout: fixed;
}

.onSkillsetTable2Lang td {
  color: #000 !important;
  font-weight: 600;
}

.onSkillsetTable2Lang th {
  color: #44577a !important;
  font-weight: 600;
}

.onSkillsetTable2Lang th {
  width: 300px;
  background-color: transparent !important;
}

.onSkillsetTable2Lang tr {
  border-bottom: 1px solid #ccc !important;
}

.onSkillsetTable2Lang .MuiTableCell-root {
  box-shadow: none !important;
}

.ProfessionTab2 .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 700;
  font-size: 16px;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  padding-right: 20px;
}

.ProfessionTab2 .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfessionTab2 .MuiTabs-indicator {
  background-color: #135aff !important;
  width: 2% !important;
}

.ProfessionTab2 .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.theme5Background {
  background: linear-gradient(to bottom, #503760, #e6e6e7);
  border-bottom: 1px solid #eee;
}

.theme4Background {
  background: #f6f4ed;
  border-radius: 4px !important;
  border: 1px solid #063d44;
}

.theme5Background2 {
  background-color: #fff;
  border-radius: 4px !important;
  border: 1px solid #ccc;
}

.theme5Background3 {
  /* background-color: #fff;
  border-radius: 4px !important;
  border: 1px solid #ccc; */
  background: #f6e2f0;
  padding: 16px;
  height: 100%;
  border-radius: 4px;
}

.theme5fieldset {
  border-radius: 5px;
  border: 1px solid #b9abc4;
  padding: 2rem;
  background: #f6e2f0;
}

.theme5fieldset legend {
  /* margin-left: 1.5rem !important; */
  padding: 8px 16px !important;
  padding-right: 5rem !important;
  background: #503760;
  border-radius: 3px;
}

.screenShotTheme5SideHeading {
  border-radius: 5px;
  border: 1px solid #b9abc4;
  padding: 2rem;
  background: #f6e2f0;
}

.screenShotTheme5SideHeading legend {
  /* margin-left: 1.5rem !important; */
  padding: 8px 16px !important;
  padding-right: 5rem !important;
  background: #503760;
  border-radius: 3px;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.ProfessionTab5 .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 700;
  font-size: 16px;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  padding-right: 20px;
}

.ProfessionTab5 .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfessionTab5 .MuiTabs-indicator {
  background-color: #c70039 !important;
  width: 2% !important;
}

.ProfessionTab5 .MuiTabs-flexContainer {
  justify-content: center !important;
}

.theme5SkillsetTabnew .MuiTabs-flexContainer {
  justify-content: left !important;
}


.ProfessionTab5 .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.theme5SkillsetTab {
  position: relative;
}

.theme5SkillsetTab .MuiTabs-root {
  position: absolute;
  top: 3rem;
  left: 30%;
}

.theme5SkillsetTab .MuiTabs-indicator {
  background-color: #c70039 !important;
  width: 7% !important;
}

.themeHeaderBtn .MuiButtonBase-root {
  font-weight: 500;
  color: #000;
}

.dashboardSlider .slick-arrow {
  top: -28%;
}

.dashboardSlider .slick-arrow.slick-prev {
  left: initial;
  right: 10%;
}

.dashboardSlider .slick-arrow.slick-next {
  right: -5%;
}

.pLength {
  position: relative;
  right: 9.5%;
}

.mainTitle {
  /* margin-bottom: 90px; */
  padding-bottom: 90px !important;
}

.theme3Background {
  background-color: #fcf2f5;
  border-radius: 4px !important;
  /* border: 1px solid #ccc; */
}

.theme3BackgroundImgText {
  position: absolute;
  left: 30%;
  bottom: 25%;
  color: #fff;
  /* font-size: 45px !important;
  font-weight: 600 !important; */
}

.theme3SocialIcons .socialIcons {
  justify-content: end;
}

.theme3SocialIcons .socialIcons .MuiIconButton-root {
  padding-right: 0px !important;
  padding-left: 8px !important;
}

.theme3ProfileIntro::-webkit-scrollbar {
  width: 3px;
}

.theme3ProfileIntro::-webkit-scrollbar-thumb {
  background-color: #c7013a;
  height: 10px;
}

.theme3ProfileIntro::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.theme4ProfileIntro::-webkit-scrollbar-thumb {
  background-color: #063d44;
}

.theme3SideHeading {
  background: #c7013a;
  display: inline;
  padding: 8px 16px !important;
  padding-right: 5rem !important;
  border-radius: 3px;
  color: #fff;
}

.screenShotTheme3SideHeading {
  background: #c7013a;
  display: inline;
  padding: 8px 16px !important;
  padding-right: 5rem !important;
  border-radius: 3px;
  color: #fff;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}

.ProfessionTab3 .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 500;
  font-size: 17px;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  padding-right: 20px;
  color: #c70039 !important;
}

.ProfessionTab3 .Mui-selected {
  font-weight: 700 !important;
}

.ProfessionTab3 .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfessionTab3 .MuiTabs-indicator {
  background-color: #c70039 !important;
  width: 2% !important;
}

.ProfessionTab3 .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.socialLinks a {
  text-decoration: none !important;
  color: #000 !important;
}

.profileThemePageHeader {
  position: relative;
  z-index: 2;
}


.profileHeader {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.theme3PaddingLeft .onSkillsetTable2Lang th,
.theme3PaddingLeft .onSkillsetTable2 th {
  padding-left: 0px !important;
}

.theme3PaddingLeft .onSkillsetTable2Lang2 th,
.theme3PaddingLeft .onSkillsetTable2 th {
  padding-left: 0px !important;
  text-align: justify;
  background-color: transparent !important
}

.theme3PaddingLeft .onSkillsetTable2Lang2 td,
.theme3PaddingLeft .onSkillsetTable2 td {
  padding-left: 0px !important;
  text-align: justify;
  background-color: transparent !important
}

.theme3PaddingLeft .onSkillsetTable2Lang2 td:first-of-type,
.theme3PaddingLeft .onSkillsetTable2 td:first-of-type {
  padding-left: 0px !important;
  text-align: justify;
  background-color: transparent !important;
}

.onSkillsetTable2Lang2 tr {
  border-bottom: 1px solid #ccc !important;
}

.theme3PaddingLeft .paddingLeft>div {
  padding-left: 0px !important;
}

.theme4headerBtnBg {
  background: yellow;
  padding: 4px 24px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  /* padding-right: 20%; */
  width: 55%;
}

@media only screen and (max-width: 992px) {
  .theme4headerBtnBg {
    width: auto;
  }
}

.theme4FollowBox {
  display: flex;
  height: 40px;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.theme4SideHeading {
  background: #063d44;
  display: inline;
  padding: 8px 24px !important;
  border-radius: 7px;
  color: #ddd92a;
}

.screenShotTheme4SideHeading {
  background: #063d44;
  display: inline;
  padding: 8px 24px !important;
  border-radius: 7px;
  color: #ddd92a;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}

.ProfessionTab4 .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 500;
  font-size: 17px;
  min-height: 40px !important;
  border-top-right-radius: 0px;
  padding-right: 20px;
  color: #063d44 !important;
}

.ProfessionTab4 .Mui-selected {
  font-weight: 700 !important;
  color: #c70039 !important;
}

.ProfessionTab4 .MuiTabScrollButton-root {
  border: none !important;
  width: 30px !important;
}

.ProfessionTab4 .MuiTabs-indicator {
  background-color: #c70039 !important;
  width: 2% !important;
}

.ProfessionTab4 .MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.ProfessionTab4 .MuiTabs-flexContainer {
  justify-content: center !important;
}

.tableActivity {
  width: 100%;
  text-align: left;
  border: 1px solid #d3e1ea;
  border-radius: 10px !important;
  border-collapse: collapse;
}

.tableActivity th {
  padding-left: 15px !important;
  background-color: #16b358 !important;
  color: #fff;
}

.tableActivity th,td {
  padding: 12px 12px;
}

.theme4TableHead .onSkillsetTable2 th {
  color: #063d44 !important;
}

.theme4TableHead .onSkillsetTable2Lang th {
  color: #063d44 !important;
}

.rainbow-button {
  /* Medium Color */
  /* background: linear-gradient(45deg,
      #d88df0,
      #a991ff,
      #92b9ff,
      #93e7ab,
      #ffeb9c,
      #ffc48c,
      #ff8f8f); */
  background: linear-gradient(45deg,
      #c000ff,
      /* Bolder Violet */
      #6b66ff,
      /* Bolder Indigo */
      #0052cc,
      /* Bolder Blue */
      #00b33c,
      /* Bolder Green */
      #ffd600,
      /* Bolder Yellow */
      #ff8100,
      /* Bolder Orange */
      #e60000
      /* Bolder Red */
    );
  color: #fff !important;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.rainbowIcon {
  background: linear-gradient(45deg,
      #c000ff,
      /* Bolder Violet */
      #6b66ff,
      /* Bolder Indigo */
      #0052cc,
      /* Bolder Blue */
      #00b33c,
      /* Bolder Green */
      #ffd600,
      /* Bolder Yellow */
      #ff8100,
      /* Bolder Orange */
      #e60000
      /* Bolder Red */
    );
  color: #fff !important;
  border-radius: 20px;
}

.themeContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 3rem;
}

.profileProfessionlineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subsciptionToggle {
  padding: 4px !important;
  border: 0.5px solid #eee !important;
  /* box-shadow: none; */
  width: fit-content;
  border-radius: 8px;
}

.subsciptionToggle .MuiButtonBase-root {
  padding: 5px 40px;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.activitySearchIcon {
  background: #d3e1ea;
  border-radius: 10px !important;
  border: 0.5px solid rgba(145, 158, 171, 1) !important;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30rem;
  font-size: 18px;
  font-weight: 500;
}

.textfieldcolorName .MuiInputBase-input {
  background-color: #f2f2f2;
  text-transform: capitalize;
}

.paymentSuccIcon {
  background-color: green;
  border-radius: 50%;
}

.paymentFailIcon {
  background-color: red;
  border-radius: 50%;
}

.PaymentHistoryTable th {
  /* background-color: #a6add3 !important; */
  background-color: #faf8e1 !important;
  color: #0b4029;
  padding: 7px;
  padding: 7px 16px;
  border-radius: 0px !important;
  font-size: 15px !important;
  line-height: 1.8rem !important;
}

.PaymentHistoryTable td {
  font-size: 15px !important;
  font-weight: 600;
}

.PaymentHistoryTable .MuiTableCell-root {
  box-shadow: none !important;
}

.tablerow2 {
  padding-left: 10px !important;
}

.activityCircleIcon {
  color: #0b4029;
  background: #0b4029;
  border-radius: 50%;
  box-shadow: 0px 0px 6px black;
  font-size: 7px !important;
}

.SkeletonColourb {
  background-color: #e4e4e4 !important;
}

.billingCardBackground {
  background: linear-gradient(to bottom right,
      black 0%,
      #0b4029 65%) !important;
}

.BillingCardBorder {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
}

.textfieldheight input {
  height: 10px;
}

.subtext {
  font-size: 16px !important;
  color: gray !important;
}

.billingtextBlack {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.backButton.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.iconLeft {
  padding-left: 0px !important;
}

.billingScroller::-webkit-scrollbar {
  width: 3px;
}

.billingScroller::-webkit-scrollbar-thumb {
  background-color: #ff9a00;
  height: 10px;
}

.billingScroller::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.couponCodeText input {
  color: #fff !important;
}

.subBtn {
  font-weight: 400 !important;
  font-size: 15px !important;
  padding: 8px 16px !important;
}

.subscriptionList tr {
  border-bottom: 1px solid #ccc !important;
}

.subscriptionList tr:last-child {
  border-bottom: none !important;
}

.recentEmployerViewImg_container {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  /* 3.5:5 aspect ratio (5 / 3.5 * 100) */
  overflow: hidden;
  border-radius: 5px;
}

.recentEmployerViewImg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
}

.dashboardShareSlider .slick-slide {
  text-align: center !important;
  /* width: fit-content !important; */
  /* width: 100px !important; */
}

/* .dashboardShareSlider .slick-slide + div {
  width: 100px !important;
} */

.dashboardShareCopy {
  align-items: center !important;

  border: 2px solid #ccc;
  border-radius: 8px;
}

.boxWithCuttingEffect {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.boxWithCuttingEffect::before,
.boxWithCuttingEffect::after {
  content: "";
  position: absolute;
  top: -8px;
  bottom: -9px;
  width: 16px;
  height: 16px;
  background-color: #ebebeb;
  border-radius: 50%;
}

.boxWithCuttingEffect::before {
  left: -31px;
  /* Position the cut on the left */
  transform: rotate(-45deg);
  /* Rotate the cut */
}

.boxWithCuttingEffect::after {
  right: -31px;
  /* Position the cut on the right */
  transform: rotate(45deg);
  /* Rotate the cut */
}

.couponValidity b {
  font-weight: 400 !important;
}

.SubscriptionShadow {
  box-shadow: 0 0 15px #d5d5d5 !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingPageTabIndicator .MuiTabs-indicator {
  /* background-color: none !important; */
  display: none !important;
}

.invoiceDetailsTable td {
  font-weight: 700 !important;
}

.projectTimelineProduction {
  border: 2px solid #0a5434;
  border-radius: 8px;
  background: #ecfff4;
}

.projectTimelinePre-Production {
  border: 2px solid #d58626;
  border-radius: 8px;
  background: #fff6eb;
}

.projectTimelinePost-Production {
  border: 2px solid #5856d6;
  border-radius: 8px;
  background: #f8f8ff;
}

.projectTimelineBottomProduction {
  border-bottom: 1px solid #0a5434;
}

.projectTimelineBottomPre-Production {
  border-bottom: 1px solid #d58626;
}

.projectTimelineBottomPost-Production {
  border-bottom: 1px solid #5856d6;
}

.projectTimelineDaysProduction {
  background: #0a5434;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 8px;
}

.projectTimelineDaysPre-Production {
  background: #d58626;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 8px;
}

.projectTimelineDaysPost-Production {
  background: #5856d6;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 8px;
}

.projectTimelineDateProduction div {
  background: #0a5434;
}

.projectTimelineDatePre-Production div {
  background: #d58626;
}

.projectTimelineDatePost-Production div {
  background: #5856d6;
}

.projectTimelineColorProduction div {
  color: #0a5434;
}

.projectTimelineColorPre-Production div {
  color: #d58626;
}

.projectTimelineColorPost-Production div {
  color: #5856d6;
}

.projectTimelineDate div {
  color: #fff;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  border-radius: 10px;
}

.shootingSecBox {
  border: 2px solid #ccc;
  padding: 8px 14px;
  border-radius: 10px;
  /* min-height: 10rem; */
}

.inboxLeftSideBox {
  border: 0.1px solid #d3e1ea;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.inboxEquiryCard {
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  margin-bottom: 12px;
  box-shadow: none !important;
}

.inboxLeftGrid {
  padding-right: 8px;
  border-right: 1px solid #d3e1ea;
}

.inboxCountBox {
  background: green;
  padding: 6px;
  border-radius: 50%;
  margin-right: 8px;
}

.inboxCountBox p {
  line-height: 10px;
  color: #fff;
}

#contactPageDialog .MuiDialog-container {
  align-items: flex-start !important;
  justify-content: flex-end !important;
  height: 100% !important;
}

#contactPageDialog .MuiDialog-container>.MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  height: 100% !important;
  overflow-y: auto !important;
}

.qustion-flex {
  display: flex;
  justify-content: space-between;
}

.ProjectIndex .MuiInputLabel-root {
  z-index: 0 !important;
}

.SelectIncharge .css-1fdsijx-ValueContainer {
  height: 60px !important;
  border-radius: 8px !important;
}


.SelectIncharge .css-hlgwow {
  height: 60px !important;
  border-radius: 8px !important;
}


.themePlanBtn {
  position: absolute;
  top: 6%;
  right: -7%;
  z-index: 1;
}

.themePlanBtn .MuiButtonBase-root {
  font-size: 12px;
  padding: 3px 11px;
  min-width: 13px px;
}

.workCreditTable001 table tr th {
  color: #44577a !important;
}

.locationTableTheme table tr th {
  color: #44577a !important;
  font-weight: 600;
}

.activityInfoTable th {
  background-color: #a6add3 !important;
  color: #030f54;
  font-weight: 400;
  padding: 7px 16px;
  border-radius: 0px !important;
  font-size: 15px !important;
  line-height: 1.8rem !important;
}

.activityInfoTable td {
  font-size: 15px !important;
  font-weight: 400;
}

.activityInfoTable thead tr th {
  border: 1px solid #ccc !important;
}

.activityInfoTable tbody tr td {
  border: 1px solid #ccc !important;
}


.activityInfoTable .MuiTableCell-root {
  box-shadow: none !important;
}

.activityInfoTableCellHeight {
  max-height: 4rem !important;
  overflow: auto;
  word-break: break-all;
  padding-right: 5px;
}

.greenBorder {
  border: 1px solid #42ab5b;
}

.flexed {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.resumeMainHeadings {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #000;
}

.resumeMainHeadingsScreenshot {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #000;
  padding-bottom: 1rem;
}

.resumeSideHeadingsScreenshot {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: '#44577a';
  padding-bottom: 1rem;
}

.resumeSideHeadings {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: '#44577a';
}

.resumeTable tbody tr th {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.resumeTable tbody tr td {
  font-size: 15px !important;
}

/* .resumeTable td::first-letter {
  text-transform: uppercase !important;
} */

.resumeTable2 thead tr th {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}


.resumeTable2 tbody tr td {
  font-size: 15px !important;
}

.resumeTable2 .MuiTableCell-root {
  box-shadow: none !important;
}


.resumeTable2 td::first-letter {
  text-transform: uppercase !important;
}

.resumeWorkCreditTab .MuiTabs-indicator {
  display: none !important;
}

.Dateformatee b {
  font-weight: 500 !important;
}

.DateformateeD b {
  font-weight: normal !important;
}

.noCouponsAvailable {
  width: 100%;
  padding: 1rem;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calenderArrowDate {
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
}



.Tablepayment th {
  padding: 0px 0px 0px 0px !important;
}

.calenderDays:first-child {
  border-radius: 5px !important;
}

.calenderDays:last-child {
  border-radius: 5px !important;
}

.calenderDays {
  width: 75px !important;
  border: none !important;
  display: inline-block;
  margin-right: -5px !important;
  font-weight: normal !important;
  /* border-radius: 0 !important; */
  border-radius: 5px !important;
}


.greenDate b {
  color: #006742;
}

.QuickSearchAccordian .Mui-expanded {
  margin: 0px !important;
  min-height: 27px !important;
}

.QuickSearchAccordian .MuiAccordionDetails-root {
  padding: 0px 16px 8px !important;
}

.QuickSearchAccordian .MuiAccordionSummary-root .Mui-expanded {
  min-height: 27px !important;
}









/* ----------------------------calendar_new---------- */
a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] .fc-event-main-frame {
  display: block !important;

}

a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] .fc-event-title {
  width: 95% !important;
  white-space: nowrap !important;
}

@media (max-width: 992px) {
  a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] .fc-event-title {
    white-space: normal !important;
  }
}

a.fc-daygrid-event[style="background-color: rgb(27, 170, 90);"] .fc-daygrid-block-event {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.fc .fc-daygrid-event {
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-top: 4px !important;
}

a.fc-daygrid-event[style="background-color: rgb(27, 170, 90);"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 111;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}




.fc .fc-daygrid-event-harness {
  /* position: absolute; */
  position: relative;
  top: 0px;
  left: 0px;
  margin-top: 0 !important;
  visibility: visible !important;
  cursor: pointer;
}

.fc-popover-body .fc-h-event .fc-event-title {
  display: inline-block !important;
  white-space: wrap !important;
}

.fc-popover-body a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] {
  height: 3.5rem;
  border-radius: 8px !important;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}



.fc-popover-body a.fc-daygrid-event[style="background-color: rgb(27, 170, 90);"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

.fc-popover .fc-more-popover {
  width: 15rem !important;
}


.fc .fc-daygrid-more-link {
  z-index: 22222 !important;
}


/* .fc .fc-daygrid-event-harness {
  position: relative;
} */

.fc .fc-daygrid-event-harness-abs {
  position: absolute !important;
}

.fc .fc-daygrid-day-number {
  font-size: 20px !important;
}

.fc-daygrid-day .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}

.fc .fc-event .fc-event-main {
  width: -webkit-fill-available !important;
}

/* .MuiListItemText-root .RootChild1 {
  color: #d7e0dc !important;
} */


/* .mainTitle .MuiListItemButton-root .RootChild1 {
  color: #d7e0dc !important;
} */

.SkillsetProfileBar .MuiLinearProgress-colorPrimary {
  background-color: #fff !important;
}

.Teamdashscroll {
  max-height: 10rem;
  overflow: auto;
}

.Teamdashscroll::-webkit-scrollbar {
  width: 5px;
}

.Teamdashscroll::-webkit-scrollbar-thumb {
  background-color: #44577a;
  height: 10px;
}

.Teamdashscroll::-webkit-scrollbar-track {
  background-color: #d9dde4;
}


.SubscriptionTeamdashscroll::-webkit-scrollbar {
  width: 5px;
}

.SubscriptionTeamdashscroll::-webkit-scrollbar-thumb {
  background-color: #d9dde4;

  height: 10px;
}

.SubscriptionTeamdashscroll::-webkit-scrollbar-track {
  background-color: #44577a;
}

#overlay header {
  width: fit-content;
}

.advanceTranferList .MuiListItemText-root span::first-letter {
  text-transform: uppercase !important;
}

/* .advanceTranferList .MuiListItemText-root span {
  color: red !important;
} */

.jobTableImg img {
  height: 8rem !important;
}



.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
 min-height: 10rem;
  overflow: auto;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {

  min-height: 10rem;
  overflow: auto;
}

.ThemeProfileCard {
  height: 350px;
  min-width: 300px;
  border: 0.5px solid #D3D1EA;
  border-radius: 0px !important;
  background-color: #e4e4e4,
}


/* App.css */
.custom-select__menu {
  z-index: 9999 !important; 
  max-height: 15rem !important;
}

.custom-select__menu-list {
  z-index: 1 !important;  
  max-height: 15rem !important;
   cursor: pointer !important;
}
.custom-select__control{
  height: 53px;
    border-radius: 8px !important;
    cursor: pointer !important;
}

.custom-select__indicator-separator{
  display: none;
}

.custom-select__option--is-selected {
  background-color: #fff !important;
  color:  #000 !important;
  cursor: pointer !important;
}


.custom-select__option--is-selected:hover{
  background-color: #B2D4FF !important;
}


@media (max-width: 768px) {
  .mobileEditIcon {
    width: 20px !important;
    height: 20px !important;
  }

  .mobileIconCell {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .mobileIconCell .MuiIconButton-root {
    padding: 5px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mobileEditIcon {
    width: 22px !important;
    height: 22px !important;
  }

  .mobileIconCell {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .mobileIconCell .MuiIconButton-root {
    padding: 5px !important;
  }
}

.coverLetterEditorView p{
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .coverLetterEditorView td{
padding: 0px;
} */



.workCreditTableHeader th {
  /* border: none !important; */
  background-color: transparent !important;
  box-shadow: none !important;
}


.inboxRightBox {
  max-height: 50rem;
  overflow-y: auto;
  padding-right: 5px;
}